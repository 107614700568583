import { Helmet } from "react-helmet-async";

const MetaHeader = ({ title, description, keywords }) => (
  <>
    <Helmet>
      <title>{"Skyward Xperia Website | " + title}</title>
      <meta
        name="description"
        content={
          <span dangerouslySetInnerHTML={{ __html: description }}></span>
        }
      />
      <meta name="keywords" content={keywords} />
    </Helmet>
  </>
);

export default MetaHeader;
