import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "commonReducer",
  initialState: {
    value: true,
    isLoading: false,
    searchData: {
      departure: "",
      start_date: "",
      end_date: "",
    },
    searchGuestData: [
      {
        title: "Adults",
        ageRange: "Ages 12 or above",
        key: "adults",
        count: 1,
      },
      { title: "Children", ageRange: "Ages 5–12", key: "age_5_12", count: 0 },
      { title: "Children", ageRange: "Ages 2–5", key: "age_2_5", count: 0 },
      { title: "Infants", ageRange: "Under 2", key: "age_less_2", count: 0 },
    ],
    flight_booking: true,
    selected_flight: "",
    // travelerData: {
    //   first_name: "amala",
    //   middle_name : "",
    //   last_name: "babu",
    //   email: "tintu@smarthatch.com",
    //   phone: "7025084412",
    //   country_code: {
    //     _id: "672c4fd98295db4fca2c499c",
    //     code: "IN",
    //     created_at: "2024-11-12T05:49:59.198Z",
    //     dial_code: "+91",
    //     name: "India",
    //   },
    //   nationality: {
    //     _id: "672c4fd98295db4fca2c499c",

    //     code: "IN",
    //     created_at: "2024-11-12T05:49:59.198Z",

    //     dial_code: "+91",
    //     name: "India",
    //   },
    //   dob: new Date(),
    // },
    travelerData: {
      first_name: "",
      middle_name : "",
      last_name: "",
      email: "",
      phone: "",
      country_code: "",
      nationality: "",
      dob:"",
    },
    otp_verified: false,
    promo_code: "",
    booking_completed: false,
    promo_code_applied: false,
    discount_cost: "",
    total_cost: "",
    send_otp_loader: false,
    booking_id: "",
    loginData: {
      booking_ref_id: "",
      last_name: "",
      dob: "",
    },
    currencyValue : "",
    book_now_loader : false,
    payment_process : false
  },
  reducers: {
    updateRedux: (state, data) => {
      state[data.payload.key] = data.payload.value;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
  },
});
export const { updateRedux } = commonSlice.actions;
export const { startLoading, endLoading } = commonSlice.actions;

export const value = (state) => state.commonReducer.value;

export const package_data = (state) => state.commonReducer.package_data;
export const package_page_details = (state) => state.commonReducer.package_page_details;
export const package_path = (state) => state.commonReducer.package_path;
export const package_details = (state) => state.commonReducer.package_details;
export const package_loader = (state) => state.commonReducer.package_loader;


export const airport_codes = (state) => state.commonReducer.airport_codes;

export const searchData = (state) => state.commonReducer.searchData;
export const searchGuestData = (state) => state.commonReducer.searchGuestData;

export const non_relevant_package_data = (state) => state.commonReducer.non_relevant_package_data;

export const currency_data = (state) => state.commonReducer.currency_data;

export const blog_details = (state) => state.commonReducer.blog_details;
export const blog_path = (state) => state.commonReducer.blog_path;

export const attraction_details = (state) => state.commonReducer.attraction_details;
export const attraction_path = (state) => state.commonReducer.attraction_path;

export const activity_details = (state) => state.commonReducer.activity_details;
export const activity_path = (state) => state.commonReducer.activity_path;

export const flight_booking = (state) => state.commonReducer.flight_booking;
export const flight_list = (state) => state.commonReducer.flight_list;
export const selected_flight = (state) => state.commonReducer.selected_flight;

export const country_list = (state) => state.commonReducer.country_list;

export const travelerData = (state) => state.commonReducer.travelerData;
export const otp_verified = (state) => state.commonReducer.otp_verified;
export const promo_code = (state) => state.commonReducer.promo_code;
export const booking_completed = (state) => state.commonReducer.booking_completed;
export const promo_code_applied = (state) => state.commonReducer.promo_code_applied;
export const discount_cost = (state) => state.commonReducer.discount_cost;
export const total_cost = (state) => state.commonReducer.total_cost;
export const send_otp_loader = (state) => state.commonReducer.send_otp_loader;
export const booking_id = (state) => state.commonReducer.booking_id;
export const booking_details = (state) => state.commonReducer.booking_details;

export const loginData = (state) => state.commonReducer.loginData;
export const login_otp_verified = (state) => state.commonReducer.login_otp_verified;
export const payment_process = (state ) => state.commonReducer.payment_process;
export const login_loader = (state ) => state.commonReducer.login_loader;

export const currencyValue = (state ) => state.commonReducer.currencyValue;
export const promo_loader = (state ) => state.commonReducer.promo_loader;
export const book_now_loader = (state ) => state.commonReducer.book_now_loader;

export const booking_details_loading = (state ) => state.commonReducer.booking_details_loading;
export const package_side_payment_details = (state ) => state.commonReducer.package_side_payment_details;
export const flightSearchLoader = (state ) => state.commonReducer.flightSearchLoader;

export const token_payment = (state ) => state.commonReducer.token_payment;
export const contact_details = (state ) => state.commonReducer.contact_details;
export const general_details = (state ) => state.commonReducer.general_details;
export const general_path = (state ) => state.commonReducer.general_path;
export const package_common_path = (state ) => state.commonReducer.package_common_path;
export const package_common_details = (state ) => state.commonReducer.package_common_details;

export const review_data = (state ) => state.commonReducer.review_data;
export const review_path = (state ) => state.commonReducer.review_path;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;


export default commonSlice.reducer;
