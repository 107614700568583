import React from "react";

const DynamicFontSize = ({ number, tag = "h3", currencyCode = null }) => {
  const formattedNumber =
    typeof number === "number" ? number.toLocaleString() : String(number);

  const calculateFontSize = (length) => {
    if (tag === "h5") {
      if (length <= 2) return "20px";
      return "24px";
    }
    if (length <= 8) return "36px";
    return "24px";
  };

  const fontSize = calculateFontSize(
    formattedNumber.replace(/[^0-9]/g, "").length
  );

  const TagComponent = tag;

  return (
    <TagComponent
      style={{
        fontSize,
        transition: "font-size 0.3s ease",
      }}
    >
      {formattedNumber}
    </TagComponent>
  );
};

export default DynamicFontSize;
