import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { curated } from "../../../components/web/config";
import { Link as ScrollLink, Element } from "react-scroll";
import Overview from "./Overview";
import Highlights from "./Highlights";
import Itinerary from "./Itinerary";
import Attractions from "./Attractions";
import Activities from "./Activities";
import VehicleExperience from "./VehicleExperience";
import HotelExperience from "./HotelExperience";
import IncludeExclude from "./IncludeExclude";
import Blogs from "./Blogs";
import EssentialInfo from "./EssentialInfo";
import StressFree from "./StressFree";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAirportCodes,
  getPackageDetail,
  getPackageReviews,
  packageCommonData,
} from "../../../redux/commonActionCreator";
import {
  flight_booking,
  package_common_details,
  package_common_path,
  package_details,
  package_loader,
  package_path,
  review_data,
  searchData,
  searchGuestData,
  updateRedux,
} from "../../../redux/commonReducer";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import "../../../components/web/HomeSearch.scss";
import HomeSearch from "../../../components/web/HomeSearch";
import Gallery from "./Gallery";
import Review from "./Review";
import { update_review_loader } from "../../../redux/adminReducer";
import { addReview } from "../../../redux/adminActionCreator";

export default function PackagesDetails() {
  const dispatch = useDispatch();
  const { id, booking_id } = useParams();
  const navigate = useNavigate();

  const localData = JSON.parse(localStorage.getItem("searchData"));
  const LocalGuestData = JSON.parse(localStorage.getItem("searchGuestData"));
  const LocalFlightBooking = localStorage.getItem("flight_booking");
  const flightBooking = useSelector(flight_booking);

  const details = useSelector(package_details);
  const path = useSelector(package_path);
  const data = useSelector(searchData);
  const guestData = useSelector(searchGuestData);
  const loader = useSelector(package_loader);
  const updateReviewLoader = useSelector(update_review_loader);
  const packageCommonDetails = useSelector(package_common_details);
  const packageCommonPath = useSelector(package_common_path);
  const reviewDataList = useSelector(review_data);

  const [err, setErr] = useState("");
  const [attractions, setAttractions] = useState([]);
  const [activity, setActivity] = useState([]);
  const [attractionName, setAttractionName] = useState("");
  const [activityName, setActivityName] = useState("");
  const [ratingValue, setRatingValue] = useState(0);
  const [review, setReview] = useState("");
  const [reviewData, setReviewData] = useState({
    page: 0,
    perPage: 8,
  });

  useEffect(() => {
    if (id) {
      getDataSearchHandler(data, guestData);
    }
  }, [id]);

  const getDataSearchHandler = (dataValue, guestDataValue) => {
    let raw = {
      id: id,
      booking_id: booking_id,
      departure_id: dataValue?.departure?._id ?? "",
      start_date: dataValue?.start_date
        ? moment(dataValue?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: dataValue?.end_date
        ? moment(dataValue?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: guestDataValue.find((ele) => ele.key === "adults")?.count,
        age_5_12: guestDataValue.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: guestDataValue.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: guestDataValue.find((ele) => ele.key === "age_less_2")
          ?.count,
      },
    };
    dispatch(getPackageDetail(raw));
    localStorage.setItem("searchData", JSON.stringify(dataValue));
    localStorage.setItem("searchGuestData", JSON.stringify(guestDataValue));
  };

  useEffect(() => {
    if (localData && LocalGuestData) {
      dispatch(
        updateRedux({
          key: "searchData",
          value: localData,
        })
      );
      dispatch(
        updateRedux({
          key: "searchGuestData",
          value: LocalGuestData,
        })
      );
      getDataSearchHandler(localData, LocalGuestData);
    }
    if (LocalFlightBooking) {
      dispatch(
        updateRedux({
          key: "flight_booking",
          value: LocalFlightBooking == "true" ? true : false,
        })
      );
    }
    localStorage.removeItem("selected_flight");
    dispatch(updateRedux({ key: "selected_flight", value: "" }));
    localStorage.removeItem("travelerData");
    dispatch(
      updateRedux({
        key: "travelerData",
        value: {
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          phone: "",
          country_code: "",
          nationality: "",
          dob: "",
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getAirportCodes());
    dispatch(packageCommonData());
    localStorage.removeItem("travelerData");
    dispatch(
      updateRedux({
        key: "travelerData",
        value: {
          first_name: "",
          middle_name: "",
          last_name: "",
          email: "",
          phone: "",
          country_code: "",
          nationality: "",
          dob: "",
        },
      })
    );
  }, []);

  useEffect(() => {
    if (details?.attraction?.length) {
      setAttractions([...details?.attraction]);
    }
    if (details?.activity?.length) {
      setActivity([...details?.activity]);
    }
    if (details && id) {
      getReviewDataHandler(reviewData);
    }
  }, [details]);

  useEffect(() => {
    if (
      details &&
      booking_id &&
      details?.is_completed &&
      !details?.is_reviewed
    ) {
      scrollToSection("addReviewSection");
    } else {
      scrollToSection("slider_content");
    }
  }, [details]);

  const getReviewDataHandler = (filter) => {
    let rawData = {
      // page: filter.page,
      // perPage: filter.perPage,
      package_id: details?._id ?? "",
    };
    dispatch(getPackageReviews(rawData));
  };

  const onSearch = () => {
    getDataSearchHandler(data, guestData);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const bookNowHandler = () => {
    // if (flightBooking) {
    if (
      data?.departure == "" &&
      data?.start_date == "" &&
      data?.end_date == "" &&
      guestData.reduce((sum, person) => sum + person.count, 0) == 0
    ) {
      scrollToSection("map");
      setErr("Please enter search field values");
    } else if (data?.departure == "") {
      scrollToSection("map");
      setErr("Please select departure");
    } else if (data?.start_date == "") {
      scrollToSection("map");
      setErr("Please select start date");
    } else if (data?.end_date == "") {
      scrollToSection("map");
      setErr("Please end date");
    } else if (guestData.reduce((sum, person) => sum + person.count, 0) == 0) {
      scrollToSection("map");
      setErr("Please select guest count");
    } else {
      setErr("");
      submitHandler();
    }
    // } else {
    //   submitHandler();
    // }
  };

  const submitHandler = () => {
    getDataSearchHandler(data, guestData);
    localStorage.setItem("flight_booking", flightBooking);
    localStorage.setItem("package_path", path);
    localStorage.setItem("package_details", JSON.stringify(details));
    localStorage.setItem("searchData", JSON.stringify(data));
    localStorage.setItem("searchGuestData", JSON.stringify(guestData));
    navigate("/package-booking");
  };

  const attractionHandler = (ele, i) => {
    if (ele?.attraction?.length) {
      setAttractions([...ele?.attraction]);
      setAttractionName(`Day ${i + 1} :${ele?.title}`);
      setActivity([...details?.activity]);
      scrollToSection("attraction");
    }
  };

  const activityHandler = (ele, i) => {
    if (ele?.activity?.length) {
      setActivity([...ele?.activity]);
      setActivityName(`Day ${i + 1} :${ele?.title}`);
      setAttractions([...details?.attraction]);
      scrollToSection("activity");
    }
  };

  const reviewSubmit = (raw) => {
    dispatch(
      addReview(raw, () => {
        getDataSearchHandler(data, guestData);
        setRatingValue(0);
        setReview("");
      })
    );
  };

  const handlePageChange = () => {
    let temp;
    temp = {
      page: data?.page,
      perPage: data?.perPage + 4,
    };
    setReviewData({
      ...temp,
    });
    getReviewDataHandler(temp);
  };

  return (
    <section className="skyward__package_details p-0">
      <>
        <div className="slider__content" id="slider_content">
          <Slider {...curated}>
            {details?.banner_image?.map((ele, i) => {
              return ele?.banner_type == "image" ? (
                <div className="image_slide">
                  <img
                    src={`${IMAGE_BASE_URL}${path}/${ele?.banner_item}`}
                    alt={ele.banner_alt}
                    className="w-100 main"
                  />
                </div>
              ) : (
                <div className="image_slide">
                  <video
                    muted
                    autoPlay
                    playsInline
                    loop
                    className="masked-video"
                    src={`${IMAGE_BASE_URL}${path}/${ele.banner_item}`}
                  />
                </div>
              );
            })}
          </Slider>
          <div className="content">
            <h1>{details?.package_name}</h1>
            <div className="btns" id="map">
              <button
                className="btn btn-secondary details"
                onClick={() => scrollToSection("itinerary")}
              >
                View on map
                <img src="/assets/img/icons/button-arrow.svg" alt="" />
              </button>
              <button
                className="btn btn-secondary details"
                onClick={() => scrollToSection("gallery")}
              >
                View Gallery
                <img src="/assets/img/icons/button-arrow.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="package__features">
          <div className="gridFeatures">
            <ul>
              <li>
                <ScrollLink
                  activeClass="active"
                  to="overview"
                  spy={true}
                  smooth={true}
                  offset={-140}
                  duration={500}
                >
                  OVERVIEW
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  activeClass="active"
                  to="highlight"
                  spy={true}
                  smooth={true}
                  offset={-140}
                  duration={500}
                >
                  HIGHLIGHTS
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  activeClass="active"
                  to="itinerary"
                  spy={true}
                  smooth={true}
                  offset={-140}
                  duration={500}
                >
                  ITINERARY
                </ScrollLink>
              </li>
              {details?.attraction?.length ? (
                <li>
                  <ScrollLink
                    activeClass="active"
                    to="attraction"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                  >
                    ATTRACTIONS
                  </ScrollLink>
                </li>
              ) : (
                <></>
              )}
              {details?.activity?.length ? (
                <li>
                  <ScrollLink
                    activeClass="active"
                    to="activities"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                  >
                    ACTIVITIES
                  </ScrollLink>
                </li>
              ) : (
                <></>
              )}
              <li>
                <ScrollLink
                  activeClass="active"
                  to="vehicle"
                  spy={true}
                  smooth={true}
                  offset={-140}
                  duration={500}
                >
                  VEHICLE EXPERIENCE
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  activeClass="active"
                  to="hotel"
                  spy={true}
                  smooth={true}
                  offset={-140}
                  duration={500}
                >
                  HOTEL EXPERIENCE
                </ScrollLink>
              </li>
              {details?.inclusions?.length || details?.exclusions?.length ? (
                <li>
                  <ScrollLink
                    activeClass="active"
                    to="included"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                  >
                    INCLUDED AND EXCLUDED
                  </ScrollLink>
                </li>
              ) : (
                <></>
              )}
              {details?.blog?.length ? (
                <li>
                  <ScrollLink
                    activeClass="active"
                    to="blogs"
                    spy={true}
                    smooth={true}
                    offset={-140}
                    duration={500}
                  >
                    BLOGS
                  </ScrollLink>
                </li>
              ) : (
                <></>
              )}
              <li>
                <ScrollLink
                  activeClass="active"
                  to="info"
                  spy={true}
                  smooth={true}
                  offset={-170}
                  duration={500}
                >
                  ESSENTIAL INFO
                </ScrollLink>
              </li>
            </ul>
          </div>

          <Element name="overview" className="section">
            <div className="container" id="homeSearch">
              <HomeSearch btnTitle={"Modify"} onSearch={onSearch} />
              <Overview
                details={details}
                bookNowHandler={bookNowHandler}
                flightBooking={flightBooking}
                err={err}
                packageCommonPath={packageCommonPath}
                packageCommonDetails={packageCommonDetails}
              />
            </div>
          </Element>
          <Element name="highlight" className="section">
            <Highlights details={details} />
          </Element>
          <Element name="itinerary" className="section" id="itinerary">
            <Itinerary
              details={details}
              path={path}
              attractionHandler={attractionHandler}
              activityHandler={activityHandler}
            />
          </Element>
          {details?.attraction?.length ? (
            <Element name="attraction" className="section" id="attraction">
              <Attractions
                attractions={attractions}
                attractionName={attractionName}
              />
            </Element>
          ) : (
            <></>
          )}
          {details?.activity?.length ? (
            <Element name="activities" className="section" id="activity">
              <Activities activity={activity} activityName={activityName} />
            </Element>
          ) : (
            <></>
          )}
          <Element name="vehicle" className="section">
            <VehicleExperience details={details} />
          </Element>
          <Element name="hotel" className="section">
            <HotelExperience details={details} />
          </Element>
          <Element name="review" className="section">
            <Review
              id={id}
              details={details}
              booking_id={booking_id}
              updateReviewLoader={updateReviewLoader}
              review={review}
              setReview={setReview}
              ratingValue={ratingValue}
              setRatingValue={setRatingValue}
              reviewSubmit={reviewSubmit}
              reviewDataList={reviewDataList}
            />
          </Element>
          <Element name="gallery" className="section" id="gallery">
            <Gallery details={details} path={path} />
          </Element>
          {details?.inclusions?.length || details?.exclusions?.length ? (
            <Element name="included" className="section">
              <IncludeExclude details={details} />
            </Element>
          ) : (
            <></>
          )}
          {details?.blog?.length ? (
            <Element name="blogs" className="section">
              <Blogs details={details} />
            </Element>
          ) : (
            <></>
          )}
          <Element name="info" className="section">
            <EssentialInfo details={details} />
          </Element>
        </div>
        <StressFree
          flightBooking={flightBooking}
          bookNowHandler={bookNowHandler}
        />
      </>
    </section>
  );
}
