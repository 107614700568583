import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWizard } from "rhf-wizard";
import { useDispatch, useSelector } from "react-redux";
import {
  booking_completed,
  otp_verified,
  travelerData,
  updateRedux,
} from "../../../../redux/commonReducer";
import moment from "moment";

export default function Navigation({ onSearch }) {
  const { goToStep, activeStep } = useWizard();
  const { id } = activeStep;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const flightBooking = localStorage.getItem("flight_booking");
  const details = JSON.parse(localStorage.getItem("package_details"));
  const localData = JSON.parse(localStorage.getItem("searchData"));
  const localGuestData = JSON.parse(localStorage.getItem("searchGuestData"));
  const travelerDataList = JSON.parse(localStorage.getItem("travelerData"));

  const verifiedOtp = useSelector(otp_verified);
  const bookingCompleted = useSelector(booking_completed);
  const data = useSelector(travelerData);

  useEffect(() => {
    console.log(travelerDataList?.email);
    if (!travelerDataList?.email?.trim()?.length) {
      dispatch(
        updateRedux({
          key: "booking_completed",
          value: false,
        })
      );
      dispatch(
        updateRedux({
          key: "book_now_loader",
          value: false,
        })
      );
      dispatch(
        updateRedux({
          key: "payment_process",
          value: false,
        })
      );
      localStorage.removeItem("otp_verified");
    }
    if (!localData) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="wizard__btns">
        <div className="grid">
          <Link to={`/package-details/${details?.handle_key}`}>
            <div className="number_count first active">01</div>
          </Link>
          <h6>Tour Package</h6>
        </div>
        {flightBooking === "true" ? (
          <div
            className="grid"
            disabled={bookingCompleted}
            style={{ pointerEvents: !bookingCompleted ? "visible" : "none" }}
          >
            <div
              className={`number_count ${
                id == 0 || id == 1 || id == 2 ? "active" : ""
              }`}
              onClick={() => (!bookingCompleted ? goToStep(0) : {})}
            >
              02
            </div>
            <h6>Select Flight</h6>
          </div>
        ) : (
          <></>
        )}

        <div
          className="grid"
          disabled={bookingCompleted}
          style={{ pointerEvents: !bookingCompleted ? "visible" : "none" }}
        >
          <div
            className={`number_count ${
              id == 1 || id == 2 || flightBooking === "false" ? "active" : ""
            }`}
            onClick={() =>
              !bookingCompleted
                ? goToStep(flightBooking === "true" ? 1 : 0)
                : {}
            }
          >
            {flightBooking === "true" ? "03" : "02"}
          </div>
          <h6>Review</h6>
        </div>
        <div
          className="grid"
          disabled={!bookingCompleted}
          style={{ pointerEvents: bookingCompleted ? "visible" : "none" }}
        >
          <div
            className={`number_count last ${id == 2 ? "active" : ""}`}
            onClick={() =>
              bookingCompleted ? goToStep(flightBooking === "true" ? 2 : 1) : {}
            }
          >
            {flightBooking === "true" ? "04" : "03"}
          </div>
          <h6>Payment</h6>
        </div>
      </div>
      {!bookingCompleted ? (
        <div className="container">
          <div className="booking_search package_booking_disbaled">
            <div className="search__area">
              <div className="search__home">
                {[
                  {
                    id: "departure",
                    icon: "departure",
                    label: "From",
                    value: localData?.departure
                      ? `${localData?.departure?.airport_city} (${localData?.departure?.airport_code})`
                      : "",
                  },
                  {
                    id: "start_date",
                    icon: "arrival",
                    label: "Start Date",
                    // value: localData?.start_date
                    //   ? `${moment(localData?.start_date).format("MMM Do YY")}`
                    //   : "",
                    value: localData?.start_date ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${moment(localData?.start_date).format(
                            "MMMM D"
                          )}<sup>${moment(localData?.start_date)
                            .format("Do")
                            .replace(/\d+/g, "")
                            .trim()}</sup>, ${moment(
                            localData?.start_date
                          ).format("YYYY")}`,
                        }}
                      ></p>
                    ) : (
                      ""
                    ),
                  },
                  {
                    id: "end_date",
                    icon: "arrival",
                    label: "End Date",
                    // value: localData?.end_date
                    //   ? `${moment(localData?.end_date).format("MMM Do YY")}`
                    //   : "",
                    value: localData?.end_date ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${moment(localData?.end_date).format(
                            "MMMM D"
                          )}<sup>${moment(localData?.end_date)
                            .format("Do")
                            .replace(/\d+/g, "")
                            .trim()}</sup>, ${moment(
                            localData?.end_date
                          ).format("YYYY")}`,
                        }}
                      ></p>
                    ) : (
                      ""
                    ),
                  },
                  {
                    id: "guests",
                    icon: "guests",
                    label: "Guests",
                    value:
                      localGuestData?.reduce(
                        (sum, person) => sum + person.count,
                        0
                      ) ?? 0,
                  },
                ].map(({ id, icon, label, value }) => (
                  <div className="grid" key={id}>
                    <div className="title">
                      <h6>{label} </h6>
                    </div>
                    <h4>{value}</h4>
                  </div>
                ))}
                <div className="grid">
                  <button
                    className="btn btn-primary search"
                    style={{ textWrap: "nowrap" }}
                    disabled
                  >
                    MODIFY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
