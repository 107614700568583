import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import DatePicker from "react-datepicker";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  login_loader,
  loginData,
  updateRedux,
} from "../../redux/commonReducer";
import moment from "moment";
import { login, packageUserLoginVerify } from "../../redux/commonActionCreator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export default function ManageBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(loginData);
  const loader = useSelector(login_loader);

  const [isCompleted, setIsCompleted] = useState(false);
  const [otp, setOtp] = useState("");
  const [page, setPage] = useState("login");
  const [err, setErr] = useState({});
  const [endTime, setEndTime] = useState(Date.now() + 30000);

  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/admin");
    }
  }, []);

  const handleComplete = () => {
    setIsCompleted(true);
  };

  const onChangeHandler = (key, value) => {
    dispatch(
      updateRedux({
        key: "loginData",
        value: {
          ...data,
          [key]: value,
        },
      })
    );
  };

  const validate = () => {
    let temp = {};
    if (!data?.booking_ref_id?.trim()?.length) {
      temp = {
        ...temp,
        booking_ref_id: "Please enter booking id",
      };
    }
    if (!data?.last_name?.trim()?.length) {
      temp = {
        ...temp,
        last_name: "Please enter last name",
      };
    }
    if (data?.dob == "") {
      temp = {
        ...temp,
        dob: "Please select dob",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const submitHandler = () => {
    if (validate()) {
      submitDataHandler((res) => {
        if (res.status) {
          setEndTime(Date.now() + 30000);
          setPage("otp");
        } else {
          setPage("login");
        }
      });
    }
  };

  const submitDataHandler = (cb) => {
    let raw = {
      booking_ref_id: data.booking_ref_id?.trim(),
      last_name: data.last_name?.trim(),
      dob: data?.dob ? moment(data?.dob).format("DD-MM-YYYY") : "",
    };
    dispatch(
      login(raw, (res) => {
        cb(res);
      })
    );
  };

  const otpChangeHandler = (e) => {
    setOtp(e);
    if (e.length == 4) {
      let raw = {
        otp: e,
        booking_ref_id: data.booking_ref_id?.trim(),
      };
      dispatch(
        packageUserLoginVerify(raw, () => {
          toast.success("OTP verified successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          navigate("/admin");
          dispatch(updateRedux({ key: "otp_verified", value: false }));
          dispatch(updateRedux({ key: "booking_completed", value: false }));
          dispatch(updateRedux({ key: "promo_code_applied", value: false }));
          dispatch(
            updateRedux({
              key: "loginData",
              value: { booking_ref_id: "", last_name: "", dob: "" },
            })
          );
          localStorage.removeItem("searchData");
          localStorage.removeItem("searchGuestData");
          localStorage.removeItem("package_details");
          localStorage.removeItem("flight_booking");
          localStorage.removeItem("selected_flight");
          localStorage.removeItem("otp_verified");
          localStorage.removeItem("final_data");
          localStorage.removeItem("package_path");
        })
      );
    }
  };

  return (
    <div className="manage__booking">
      <img className="main" src="/assets/img/manage-booking.png" alt="" />
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h1>MANAGE booking</h1>
            {page === "login" ? (
              <>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus eget lorem egestas, auctor ipsum quis, semper elit.
                  Sed at ex laoreet erat ornare placerat. Nam gravida, metus
                  congue sagittis vestibulum, tellus purus scelerisque
                </p>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Booking reference ID*"
                      onChange={(e) =>
                        onChangeHandler("booking_ref_id", e.target?.value)
                      }
                      value={data?.booking_ref_id}
                    />
                    <span className="text-danger">{err.booking_ref_id}</span>
                    <p className="eg">Eg: SKY-IS-1233</p>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name*"
                      onChange={(e) =>
                        onChangeHandler("last_name", e.target?.value)
                      }
                      value={data?.last_name}
                    />
                    <span className="text-danger">{err.last_name}</span>
                  </div>
                  <div className="col-md-4">
                    <div className="icon">
                      <DatePicker
                        selected={data?.dob}
                        onChange={(date) => onChangeHandler("dob", date)}
                        endDate={data?.dob}
                        placeholderText="Date of Birth*"
                        maxDate={new Date()}
                        className={"form-control w-100"}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={150}
                        showYearDropdown
                      />
                      <img
                        src="/assets/img/icons/manage-booking-calendar.svg"
                        className="calendar"
                        alt=""
                      />
                      <span className="text-danger">{err.dob}</span>
                    </div>
                  </div>
                </div>
                <div className="devider"></div>
                <div
                  className="submit"
                  onClick={() => submitHandler()}
                  aria-disabled={loader}
                >
                  Submit
                  {loader ? (
                    <Spinner animation="border" />
                  ) : (
                    <img src="/assets/img/icons/button-arrow.svg" alt="" />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {page === "otp" && (
              <>
                <h5>
                  We have send an one-time password (OTP) to your registered
                  email for verification purposes
                </h5>
                <div className="otp_inputs">
                  <OTPInput
                    value={otp}
                    onChange={otpChangeHandler}
                    numInputs={4}
                    className="otp"
                    renderInput={(props) => <input {...props} />}
                  />
                  <h6>enter the OTP</h6>
                  {!isCompleted ? (
                    <Countdown
                      date={endTime}
                      zeroPadTime={2}
                      renderer={renderer}
                      onComplete={handleComplete}
                    />
                  ) : (
                    <button
                      className="btn btn-sm btn-resend"
                      onClick={() =>
                        submitDataHandler(() => {
                          setEndTime(Date.now() + 30000);
                          setIsCompleted(false);
                          setOtp("");
                        })
                      }
                      disabled={loader}
                    >
                      Resend OTP
                      {loader ? <Spinner animation="border" /> : <></>}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
