import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogDetails } from "../../redux/commonActionCreator";
import { blog_details, blog_path } from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import moment from "moment";
import PackageList from "./PackageList";
import { gallery } from "../../components/web/config";
import GallerySlider from "../../components/web/GallerySlider";
import MetaHeader from "../../commonComponents/MetaHeader/MetaHeader";

export default function BlogDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate()

  const details = useSelector(blog_details);
  const path = useSelector(blog_path);

  useEffect(() => {
    if (id) {
      dispatch(getBlogDetails(id,()=>{
        navigate("/blogs")
      }));
    }
  }, [id]);

  return (
    <section className="blog__details pt-0">
       <MetaHeader
        title={details?.meta_title}
        description={details?.meta_description}
        keywords={details?.meta_keyword}
      />
      <img
        src={`${IMAGE_BASE_URL}${path}/${details?.thumbnail}`}
        className="w-100 main"
        alt={details?.thumbnail_alt}
      />
      <div className="container">
        <h1>{details?.title}</h1>
        <div className="share">
          <p>{moment(details?.publish_date).format("DD MMMM YYYY")}</p>
          <div className="socials">
            <p>Share</p>
            <ul>
              <li>
                <img src="/assets/img/icons/fb-share.svg" alt="" />
              </li>
              <li>
                <img src="/assets/img/icons/x-share.svg" alt="" />
              </li>
              <li>
                <img src="/assets/img/icons/instagram-share.svg" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div className="details">
          <p
            className="detail-para"
            dangerouslySetInnerHTML={{ __html: details?.description1 }}
          ></p>
          <div className="row blog__images">
            <GallerySlider
              sliderFeatures={gallery}
              gallery={details?.gallery}
              path={path}
            />
          </div>
        </div>
        {details?.packages?.length ? (
          <div className="row">
            <h4 className="associated">ASSOCIATED PACKAGES</h4>
            <PackageList packageData={details?.packages?.slice(0, 4)} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}
