import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { rating_review } from "../../../components/web/config";
import { Rating } from "react-simple-star-rating";
import { useSelector } from "react-redux";
import { update_review_loader } from "../../../redux/adminReducer";
import { Spinner } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import { profile_path, review_path } from "../../../redux/commonReducer";

export default function Review({
  id,
  details,
  booking_id,
  updateReviewLoader,
  review,
  setReview,
  ratingValue,
  setRatingValue,
  reviewSubmit,
  reviewDataList,
}) {
  const [err, setErr] = useState({});

  const [readMoreState, setReadMoreState] = useState({});

  const reviewPath = useSelector(review_path);

  const handleRating = (rate) => {
    setRatingValue(rate);
  };

  const validate = () => {
    let temp = {};
    // if (ratingValue == 0) {
    //   temp = {
    //     ...temp,
    //     ratingValue: "Please choose star",
    //   };
    // }
    if (!review?.trim()?.length) {
      temp = {
        ...temp,
        review: "Please enter review",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        review: review?.trim(),
        rating: ratingValue,
        booking_id: booking_id,
        package_id: details?._id,
      };
      reviewSubmit(raw);
    }
  };

  const handleReadMore = (index) => {
    setReadMoreState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    console.log(reviewDataList, "reviewDataList"),
    (
      <>
        <div className="container-fluid p-0">
          <div className="rating__review">
            {reviewDataList?.length ? (
              <div className="container">
                <h2 className="title">RATING & REVIEW</h2>
                <Slider {...rating_review}>
                  {reviewDataList.map((ele, index) => (
                    <div className="review_grid" key={index}>
                      <div className="contentGrid">
                        <div className="quote">
                          <img src="/assets/img/icons/quote.svg" alt="" />
                        </div>
                        <div className="content">
                          <h5
                            className={readMoreState[index] ? "readmore" : ""}
                          >
                            {readMoreState[index]
                              ? ele?.review
                              : ele?.review.slice(0, 110) +
                                (ele?.review.length > 110 ? "..." : "")}
                          </h5>
                          {ele?.review.length > 110 && (
                            <p>
                              <a onClick={() => handleReadMore(index)}>
                                Read {readMoreState[index] ? "Less" : "More"}
                              </a>
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="footer">
                        <ul>
                          {Array.from({ length: Number(ele?.rating) }).map(
                            (_, index) => (
                              <li key={index}>
                                <img
                                  src="/assets/img/icons/rate1.svg"
                                  alt="Star"
                                />
                              </li>
                            )
                          )}
                        </ul>
                        <div className="name">
                          {ele?.profile_image ? (
                            <img
                              src={`${IMAGE_BASE_URL}${reviewPath}/${ele?.profile_image}`}
                              alt={ele?.image_alt}
                            />
                          ) : (
                            <img src="/assets/img/logo.svg" alt="" />
                          )}

                          <div>
                            <h4>{ele?.name}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : (
              <></>
            )}

            {/* <div className="button_center">
            <button className="btn btn-primary">
              SHOW MORE REVIEW{" "}
              <img src="/assets/img/icons/button-arrow.svg" alt="" />
            </button>
          </div> */}
            {booking_id && details?.is_completed && !details?.is_reviewed ? (
              <div className="container">
                <div className="write__review">
                  <button
                    className="btn btn-secondary mt-3"
                    style={{ borderRadius: "0px" }}
                  >
                    WRITE A REVIEW
                    <img
                      src="/assets/img/icons/button-arrow.svg"
                      height={10}
                      width={15}
                      alt=""
                    />
                  </button>
                  <h4>Rate Your Experience</h4>
                  <Rating
                    onClick={handleRating}
                    ratingValue={ratingValue}
                    fillIcon={
                      <img
                        src="/assets/img/icons/fill-star.svg"
                        alt="Filled Star"
                        height={20}
                        width={30}
                      />
                    }
                    emptyIcon={
                      <img
                        src="/assets/img/icons/star.svg"
                        alt="Filled Star"
                        height={20}
                        width={30}
                      />
                    }
                    className="mb-2"
                  />
                  <h4>What did you enjoy the most about the tour?</h4>
                  <textarea
                    className="form-control"
                    placeholder="A Short Review"
                    id=""
                    onChange={(e) => setReview(e.target.value)}
                    value={review}
                  ></textarea>
                  <span className="text-danger">{err.review}</span>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => onSubmitHandler()}
                    id="addReviewSection"
                  >
                    SUBMIT
                    {updateReviewLoader ? (
                      <Spinner animation="border" />
                    ) : (
                      <img src="/assets/img/icons/button-arrow.svg" alt="" />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    )
  );
}
