import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import Countdown from "react-countdown";
import OTPInput from "react-otp-input";
import { useWizard } from "rhf-wizard";
import PackageDetailsSection from "./PackageDetailsSection";
import { useDispatch, useSelector } from "react-redux";
import {
  country_list,
  currencyValue,
  discount_cost,
  package_side_payment_details,
  payment_process,
  promo_code,
  send_otp_loader,
  total_cost,
  travelerData,
  updateRedux,
} from "../../../../redux/commonReducer";
import Select from "react-select";
import {
  errorToast,
  getCountry,
  removePromocode,
  retrievePaymentSession,
  sendOTP,
  submitBooking,
  validateOtp,
  verifyPromotionCode,
} from "../../../../redux/commonActionCreator";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { API_BASE_URL } from "../../../../constants/configuration";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import { CurrencyConversion } from "../../../../utils/helpers";

export default function Step3() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goToNextStep, goToPreviousStep } = useWizard();

  const stripePromise = loadStripe(
    "pk_test_51QHHOmB3crXKUElWFpTCZ4eZrgDVXg4sWgAedC8OGCysN9AZyfhBBj2ckt5xVaf1lydDMoeARVZ6YxNwnLJ7VcJS00ryuGFKm7"
  );
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");
  const status = urlParams.get("status");

  const flightBooking = localStorage.getItem("flight_booking");
  const details = JSON.parse(localStorage.getItem("package_details"));
  const localData = JSON.parse(localStorage.getItem("searchData"));
  const LocalGuestData = JSON.parse(localStorage.getItem("searchGuestData"));
  const finalData = JSON.parse(localStorage.getItem("final_data"));
  const otpVerified = localStorage.getItem("otp_verified");
  const selectedFlight = JSON.parse(localStorage.getItem("selected_flight"));
  const travelerDataList = JSON.parse(localStorage.getItem("travelerData"));

  const countryData = useSelector(country_list);
  const data = useSelector(travelerData);
  const promotion_code = useSelector(promo_code);
  const total_cost_after_discount = useSelector(total_cost);
  const otpLoader = useSelector(send_otp_loader);
  const paymentProcess = useSelector(payment_process);
  const discountCost = useSelector(discount_cost);
  const sidePaymentDetails = useSelector(package_side_payment_details);
  const selectedCurrency = useSelector(currencyValue);

  const [otp, setOtp] = useState("");
  const [isCompleted, setIsCompleted] = useState(true);
  const [err, setErr] = useState({});
  const [page, setPage] = useState("info");
  const [processModal, setProcessModal] = useState(false);
  const [endTime, setEndTime] = useState(Date.now() + 30000);

  const twelveYearsAgo = new Date();
  twelveYearsAgo.setFullYear(twelveYearsAgo.getFullYear() - 12);

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  useEffect(() => {
    if (!localData && details?.handle_key) {
      navigate(`/package-details/${details?.handle_key}`);
    }
  }, []);

  useEffect(() => {
    console.log(
      status == "success" && !paymentProcess && otpVerified,
      "5",
      paymentProcess,
      otpVerified,
      status
    );
    if (status == "success" && !paymentProcess && otpVerified) {
      sessionSubmit();
      setProcessModal(true);
    } else if (status == "failed") {
      errorToast("Payment Failed");
    }
  }, [sessionId, status, otpVerified]);

  useEffect(() => {
    console.log(travelerDataList);
    if (travelerDataList) {
      dispatch(
        updateRedux({
          key: "travelerData",
          value: travelerDataList,
        })
      );
    }
    if (travelerDataList && otpVerified == "true") {
      setPage("verified");
    }
  }, []);

  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  };

  const handleComplete = () => {
    setIsCompleted(true);
  };

  const onChangeHandler = (key, value) => {
    if (key === "country_code") {
      dispatch(
        updateRedux({
          key: "travelerData",
          value: {
            ...data,
            [key]: value,
            phone: "",
          },
        })
      );
    } else {
      dispatch(
        updateRedux({
          key: "travelerData",
          value: {
            ...data,
            [key]: value,
          },
        })
      );
    }

    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.first_name?.trim()?.length) {
      temp = {
        ...temp,
        first_name: "Please enter first name",
      };
    }
    if (!data?.last_name?.trim()?.length) {
      temp = {
        ...temp,
        last_name: "Please enter last name",
      };
    }
    if (!data?.email?.trim()?.length) {
      temp = {
        ...temp,
        email: "Please enter email",
      };
    } else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (!regex.test(data?.email.trim())) {
        temp = {
          ...temp,
          email: "Please enter valid email",
        };
      }
    }
    if (data?.country_code == "") {
      temp = {
        ...temp,
        country_code: "Please select country code",
      };
    }
    if (!data?.phone?.trim()?.length) {
      temp = {
        ...temp,
        phone: "Please enter phone number",
      };
    } else if (data?.country_code?.dial_code) {
      const fullPhoneNumber = `${data.country_code.dial_code}${data.phone}`;
      const phoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

      if (!phoneNumber || !phoneNumber.isValid()) {
        temp = {
          ...temp,
          phone: "Please enter a valid phone number",
        };
      }
    }
    if (data?.nationality == "") {
      temp = {
        ...temp,
        nationality: "Please select nationality",
      };
    }
    if (data?.dob == "") {
      temp = {
        ...temp,
        dob: "Please select dob",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const submitHandler = () => {
    if (validate()) {
      submitDataHandler(() => {
        setPage("otp");
        setEndTime(Date.now() + 30000);
        setIsCompleted(false);
      });
    }
  };

  const submitDataHandler = (cb) => {
    let raw = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    };
    dispatch(
      sendOTP(raw, () => {
        cb();
      })
    );
  };

  const otpChangeHandler = (e) => {
    setOtp(e);
    if (e.length == 4) {
      let raw = {
        otp: e,
        email: data?.email,
      };
      dispatch(
        validateOtp(raw, () => {
          toast.success("OTP verified successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
          setPage("verified");
          localStorage.setItem("travelerData", JSON.stringify(data));
        })
      );
    }
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    // Call backend to create a session
    const response = await fetch(`${API_BASE_URL}createPaymentIntent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        amount: details?.token_payment,
        currency: "usd",
        email: data.email,
        first_name: data.first_name,
        package_id: details?._id,
        package_name: details?.package_name,
        base_url: window.location.origin,
      }),
    });
    const session = await response.json();
    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.session_id,
    });
    if (error) {
      localStorage.setItem("book_now_loader", false);
      dispatch(updateRedux({ key: "book_now_loader", value: false }));
    }
  };

  const onBookingHandler = () => {
    // setMainPage("payment");
    dispatch(updateRedux({ key: "book_now_loader", value: true }));
    localStorage.setItem("book_now_loader", true);
    let raw = {
      package: details?._id,
      no_of_travellers: LocalGuestData?.reduce(
        (sum, person) => sum + person.count,
        0
      ),
      number_of_guest: {
        adults: LocalGuestData?.find((ele) => ele.key === "adults")?.count,
        age_5_12: LocalGuestData?.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: LocalGuestData?.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: LocalGuestData?.find((ele) => ele.key === "age_less_2")
          ?.count,
      },
      traveller_info: [
        {
          first_name: travelerDataList.first_name,
          middle_name: travelerDataList.middle_name,
          last_name: travelerDataList.last_name,
          email: travelerDataList.email,
          phone: travelerDataList.phone,
          country_code: travelerDataList.country_code?.dial_code,
          nationality: travelerDataList.nationality?.name,
          dob: travelerDataList?.dob
            ? moment(travelerDataList?.dob).format("DD-MM-YYYY")
            : "",
        },
      ],
      start_travel_date: localData?.start_date
        ? moment(localData?.start_date).format("YYYY-MM-DD")
        : "",
      end_travel_date: localData?.end_date
        ? moment(localData?.end_date).format("YYYY-MM-DD")
        : "",
      point_of_origin: localData?.departure?._id ?? "",
      promotion_code: promotion_code,
      total_estimate:
        sidePaymentDetails?.flight?.grandTotalWithAirCost ?? details?.estimate,
      total_cost_after_discount:
        total_cost_after_discount != ""
          ? total_cost_after_discount
          : sidePaymentDetails?.flight?.grandTotalWithAirCost ??
            details?.estimate,
      total_discount: discountCost ?? 0,
      flight_booking: flightBooking == "true" ? true : false,
    };
    if (flightBooking == "true" && selectedFlight) {
      raw = {
        ...raw,
        selectedFlight: selectedFlight,
      };
    }
    localStorage.setItem("final_data", JSON.stringify(raw));
    handleCheckout();
  };

  const sessionSubmit = () => {
    let raw = {
      session_id: sessionId,
    };
    dispatch(
      retrievePaymentSession(raw, (res) => {
        toast.success(res.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        dispatch(updateRedux({ key: "payment_process", value: true }));
        onFinalBookingHandler(res);
      })
    );
  };

  const onFinalBookingHandler = (payDetails) => {
    let raw = {
      ...finalData,
      payment_id: payDetails?.payment_id,
      payment_status: payDetails?.payment_status,
      paid_amount: payDetails?.amount_total,
      receipt_url: payDetails?.receipt_url,
    };
    console.log(raw, "raw");
    dispatch(
      submitBooking(raw, (res) => {
        setProcessModal(false);
        if (res.status) {
          dispatch(
            updateRedux({
              key: "travelerData",
              value: {
                first_name: "",
                middle_name: "",
                last_name: "",
                email: "",
                phone: "",
                country_code: "",
                nationality: "",
                dob: "",
              },
            })
          );
          dispatch(updateRedux({ key: "promo_code", value: "" }));
          dispatch(updateRedux({ key: "total_cost", value: "" }));
          dispatch(updateRedux({ key: "discount_cost", value: "" }));
          dispatch(updateRedux({ key: "booking_id", value: res?.booking_id }));
          dispatch(updateRedux({ key: "book_now_loader", value: false }));
          localStorage.setItem("book_now_loader", false);
          goToNextStep();
        } else {
          setPage("info");
          setOtp("");
          localStorage.setItem("otp_verified", false);
          dispatch(updateRedux({ key: "otp_verified", value: false }));
          dispatch(updateRedux({ key: "book_now_loader", value: false }));
          localStorage.setItem("book_now_loader", false);
        }
      })
    );
  };

  const onPromoHandler = () => {
    let raw = {
      email: data.email,
      total_cost: !isNaN(
        Number(sidePaymentDetails?.flight?.grandTotalWithAirCost)
      )
        ? Number(sidePaymentDetails?.flight?.grandTotalWithAirCost)
        : Number(details?.estimate),
      promotion_code: promotion_code,
    };
    dispatch(
      verifyPromotionCode(raw, (res) => {
        if (res.status) {
          dispatch(updateRedux({ key: "promo_code_applied", value: true }));
          dispatch(updateRedux({ key: "total_cost", value: res.total_cost }));
          dispatch(
            updateRedux({ key: "discount_cost", value: res.discount_cost })
          );
        } else {
          // dispatch(updateRedux({ key: "promo_code", value: "" }));
        }
      })
    );
  };

  const onRemovePromoHandler = () => {
    let raw = {
      email: data.email,
      // total_cost: Number(sidePaymentDetails?.flight?.grandTotalWithAirCost),
      promotion_code: promotion_code,
    };
    dispatch(
      removePromocode(raw, (res) => {
        dispatch(updateRedux({ key: "promo_code_applied", value: false }));
        dispatch(updateRedux({ key: "total_cost", value: "" }));
        dispatch(updateRedux({ key: "discount_cost", value: "" }));
        dispatch(
          updateRedux({
            key: "promo_code",
            value: "",
          })
        );
      })
    );
  };

  return (
    console.log(selectedFlight),
    (
      <div className="container">
        <div className="flight__tickets">
          <div className="row">
            <div className="col-md-8">
              {flightBooking === "true" && selectedFlight ? (
                <>
                  <div className="review__section">
                    <h4>Review your Booking</h4>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="card__header">
                        <h5>
                          Start:{" "}
                          {selectedFlight?.outbound?.origin_start_time?.date
                            ? moment(
                                selectedFlight?.outbound?.origin_start_time
                                  ?.date
                              ).format("ddd, D MMM")
                            : moment(localData?.start_date).format(
                                "ddd, D MMM"
                              )}
                        </h5>
                        <p>Selected Flight</p>
                      </div>
                      <div className="trip_details">
                        <div className="grid">
                          <div className="logo">
                            {selectedFlight?.outbound?.aircraft_details?.map(
                              (el, i) => {
                                return (
                                  <div className="icon">
                                    <img src={el?.logo} alt="" />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div className="grid">
                          <div class="flight-info">
                            <div class="departure">
                              <h4 class="time">
                                {
                                  selectedFlight?.outbound?.origin_start_time
                                    ?.time
                                }
                              </h4>
                              <h5 class="city">
                                {selectedFlight?.outbound?.departure_airport}
                              </h5>
                            </div>
                            <div class="flight-details">
                              <span class="duration">
                                {" "}
                                {selectedFlight?.outbound?.duration}
                              </span>
                              <span class="plane-icon">
                                <img
                                  src="/assets/img/icons/aeroplane.svg"
                                  alt=""
                                />
                              </span>
                              <span class="dashed-line"></span>
                              <span class="stops">
                                {" "}
                                {selectedFlight?.outbound?.stop_summary}
                              </span>
                            </div>
                            <div class="arrival">
                              <h4 class="time">
                                {selectedFlight?.outbound?.reach_time?.time}
                              </h4>
                              <h5 class="city">
                                {selectedFlight?.outbound?.arrival_airport}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <p>Departure & Return</p>
                          Departure :
                          <span>
                            {selectedFlight?.outbound?.aircraft_details
                              ?.map((el) => el?.name)
                              .join(", ")}
                          </span>
                          Return :
                          <span>
                            {selectedFlight?.return?.aircraft_details
                              ?.map((el) => el?.name)
                              .join(", ")}
                          </span>
                        </div>
                      </div>

                      <div className="card__header return">
                        <h5>
                          End :{" "}
                          {selectedFlight?.return?.reach_time?.date
                            ? moment(
                                selectedFlight?.return?.reach_time?.date
                              ).format("ddd, D MMM")
                            : moment(localData?.end_date).format("ddd, D MMM")}
                        </h5>
                      </div>
                      <div className="trip_details">
                        <div className="grid">
                          <div className="logo">
                            {selectedFlight?.return?.aircraft_details?.map(
                              (el, i) => {
                                return (
                                  <div className="icon">
                                    <img src={el?.logo} alt="" />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div className="grid">
                          <div class="flight-info">
                            <div class="departure">
                              <h4 class="time">
                                {" "}
                                {selectedFlight?.return?.reach_time?.time}
                              </h4>
                              <h5 class="city">
                                {selectedFlight?.return?.arrival_airport}
                              </h5>
                            </div>
                            <div class="flight-details">
                              <span class="duration">
                                {" "}
                                {selectedFlight?.return?.duration}
                              </span>
                              <span class="plane-icon return">
                                <img
                                  src="/assets/img/icons/aeroplane.svg"
                                  alt=""
                                />
                              </span>
                              <span class="dashed-line"></span>
                              <span class="stops">
                                {selectedFlight?.return?.stop_summary}
                              </span>
                            </div>
                            <div class="arrival">
                              <h4 class="time">
                                {" "}
                                {
                                  selectedFlight?.return?.origin_start_time
                                    ?.time
                                }
                              </h4>
                              <h5 class="city">
                                {selectedFlight?.return?.departure_airport}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <p>Price per Person</p>
                          <div className="price">
                            <span className="d-flex gap-1">
                              <h3>
                                {CurrencyConversion(
                                  selectedCurrency,
                                  selectedFlight?.total_price
                                ) ?? selectedFlight?.total_price}
                              </h3>
                              <span>
                                {selectedCurrency?.currency_code ?? "USD"}
                              </span>
                            </span>
                          </div>
                          {/* <span className="d-flex gap-1">
                            <h1>
                              {}
                              {CurrencyConversion(
                                selectedCurrency,
                                selectedFlight?.total_price ?? 0
                              )}
                            </h1>{" "}
                            <h6 className="mt-2">
                              {" "}
                              {selectedCurrency?.currency_code ?? "USD"}
                            </h6>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {page == "verified" ? (
                ""
              ) : (
                <div className="review__section">
                  <h4 className="mb-1">Main traveller Details</h4>
                  <p>Please provide the details of the main traveler below</p>
                </div>
              )}

              <div className="travel__details">
                <div className="card">
                  <div className="card-body">
                    {page == "info" ? (
                      <>
                        <div className="row">
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                onChange={(e) =>
                                  onChangeHandler("first_name", e.target?.value)
                                }
                                value={data?.first_name}
                              />
                              <span className="text-danger">
                                {err.first_name}
                              </span>
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Middle Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter middle name"
                                onChange={(e) =>
                                  onChangeHandler(
                                    "middle_name",
                                    e.target?.value
                                  )
                                }
                                value={data?.middle_name}
                              />
                              <span className="text-danger">
                                {err.middle_name}
                              </span>
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter last name"
                                onChange={(e) =>
                                  onChangeHandler("last_name", e.target?.value)
                                }
                                value={data?.last_name}
                              />
                              <span className="text-danger">
                                {err.last_name}
                              </span>
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter email address"
                                onChange={(e) =>
                                  onChangeHandler("email", e.target?.value)
                                }
                                value={data?.email}
                              />
                              <span className="text-danger">{err.email}</span>
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Country Code</Form.Label>
                              <Select
                                placeholder="Select Your Country Code"
                                name="unit"
                                options={countryData}
                                value={data?.country_code}
                                getOptionLabel={(o) => o?.dial_code}
                                getOptionValue={(o) => o?._id}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => {
                                  onChangeHandler("country_code", e);
                                }}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />

                              <span className="text-danger">
                                {err.country_code}
                              </span>
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter phone Number"
                                // onChange={(e) =>
                                //   onChangeHandler("phone", e.target?.value)
                                // }
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (/^\d{0,13}$/.test(inputValue)) {
                                    onChangeHandler("phone", inputValue);
                                  }
                                }}
                                value={data?.phone}
                              />
                              <span className="text-danger">{err.phone}</span>
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Nationality</Form.Label>
                              <Select
                                placeholder="Select Your Nationality"
                                name="unit"
                                options={countryData}
                                value={data?.nationality}
                                getOptionLabel={(o) => o?.name}
                                getOptionValue={(o) => o?._id}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) =>
                                  onChangeHandler("nationality", e)
                                }
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />

                              <span className="text-danger">
                                {err.nationality}
                              </span>
                            </Form.Group>
                          </div>
                          <div className="col-md-5">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Birth Date</Form.Label>
                              <div>
                                <DatePicker
                                  selected={data?.dob}
                                  onChange={(date) =>
                                    onChangeHandler("dob", date)
                                  }
                                  endDate={data?.dob}
                                  placeholderText="Date of Birth"
                                  // maxDate={new Date()}
                                  maxDate={twelveYearsAgo}
                                  className={"form-control w-100"}
                                  dateFormat="dd/MM/yyyy"
                                  showMonthDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={150}
                                  showYearDropdown
                                />
                              </div>
                              <span className="text-danger">{err.dob}</span>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="desclaimer">
                          <p>
                            * We'll send a one-time password (OTP) to this email
                            for verification purposes. This helps us ensure the
                            security of your information.
                          </p>
                          <div
                            className="btn-next"
                            onClick={() => submitHandler()}
                          >
                            next
                            {otpLoader ? (
                              <Spinner animation="border" />
                            ) : (
                              <img
                                src="/assets/img/icons/button-arrow.svg"
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {page == "otp" ? (
                      <div className="otp_validate">
                        <p>
                          We have send an one-time password (OTP) to{" "}
                          <strong>{data.email}</strong> email for verification
                          purposes
                        </p>
                        <div className="otp_inputs">
                          <OTPInput
                            value={otp}
                            onChange={otpChangeHandler}
                            numInputs={4}
                            className="otp"
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        <h6>enter the OTP</h6>
                        {!isCompleted ? (
                          <Countdown
                            date={endTime}
                            zeroPadTime={2}
                            renderer={renderer}
                            onComplete={handleComplete}
                          />
                        ) : (
                          <div
                            className="btn btn-sm btn-resend"
                            onClick={() =>
                              submitDataHandler(() => {
                                setEndTime(Date.now() + 30000);
                                setIsCompleted(false);
                                setOtp("");
                              })
                            }
                            aria-disabled={otpLoader}
                          >
                            Resend OTP
                            {otpLoader ? <Spinner animation="border" /> : <></>}
                          </div>
                        )}
                        <div className="desclaimer">
                          <div className="btn-next">
                            VERIFY
                            <img
                              src="/assets/img/icons/button-arrow.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {page == "verified" ? (
                      <div className="otp_validate success__msg">
                        <img
                          src="/assets/img/icons/check-circle.svg"
                          className="check"
                          alt=""
                        />
                        <p>
                          <strong>Verification Successful</strong> <br /> The
                          email address you enter will be our primary point of
                          contact for all communication regarding your booking.
                          You can Proceed to the payment
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <PackageDetailsSection
              onBookingHandler={onBookingHandler}
              onPromoHandler={onPromoHandler}
              onRemovePromoHandler={onRemovePromoHandler}
            />
          </div>
        </div>
        <Modal
          show={processModal}
          centered
          onHide={() => setProcessModal(false)}
        >
          <Modal.Body>
            <div className="common__modal">
              {/* <img src="/assets/img/icons/processing.svg" alt="" className="icon" /> */}
              <h2>
                Payment Processing <br />
                <Spinner
                  style={{
                    width: "30px",
                    height: "30px",
                    marginTop: "20px",
                    borderWidth: "3px",
                  }}
                  animation="border"
                />
              </h2>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  );
}
