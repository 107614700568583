import React from "react";
import Title from "./Title";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import MetaHeader from "../../commonComponents/MetaHeader/MetaHeader";

const PolicyContent = ({ contentData }) => {
  return (
    <div className="skyward__policy">
      <MetaHeader
        title={contentData?.meta_title}
        description={contentData?.meta_description}
        keywords={contentData?.meta_keyword}
      />
      <img
        src={
          contentData.image ? `${IMAGE_BASE_URL}cms/${contentData.image}` : ""
        }
        className="w-100"
        alt={contentData.image_alt}
      />

      <div className="detailed__description">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <Title title={contentData.title} color={"var(--primary-color)"} />
              <h5
                dangerouslySetInnerHTML={{
                  __html: contentData.description,
                }}
              ></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyContent;
