
import RouteHandler from "./router/router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './pageStyles.scss'
import './responsive.scss'
import Aos from "aos";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import ScrollTop from "./components/web/ScrollTop";
function App() {

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <ScrollTop />
      {/* <Header /> */}
      <RouteHandler />
      {/* <Footer/> */}
      <ToastContainer autoClose={3000} position='top-right' />
    </>
  );
}

export default App;
