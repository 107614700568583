import { useEffect, useState } from "react";
import { gallery } from "../../../components/web/config";
import GallerySlider from "../../../components/web/GallerySlider";
import Fancybox from "../../../components/web/Fancybox";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

const Gallery = ({ details, path }) => {
  const [galleryData, setGalleryData] = useState([]);
  useEffect(() => {
    if (details?.gallery?.length) {
      let temp = [];
      details?.gallery?.forEach((ele, i) => {
        let obj = {
          image: ele?.gallery_item,
          alt: ele?.gallery_alt,
          type: ele?.gallery_type,
        };
        temp.push(obj);
      });
      setGalleryData(temp);
    }
  }, [details]);
  return (
    <>
      <div className="container">
        <div className="gallery">
          <div className="header">
            <h2 className="title">Gallery</h2>
            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              {galleryData.map((item, index) => (
                <a
                  key={index}
                  data-fancybox="gallery"
                  href={
                    item.gallery_type === "video"
                      ? item.videoUrl
                      : IMAGE_BASE_URL + "package/" + item.image
                  }
                >
                  {index === 0 && (
                    <div className="view">
                      View All
                      <img src="/assets/img/icons/btn-arrow.svg" alt="" />
                    </div>
                  )}
                  <img
                    src={
                      item.gallery_type === "video"
                        ? item.thumbnail
                        : IMAGE_BASE_URL + "package/" + item.image
                    }
                    alt={`Gallery item ${index + 1}`}
                    style={{ display: "none" }}
                  />
                </a>
              ))}
            </Fancybox>
          </div>
          <GallerySlider
            sliderFeatures={gallery}
            gallery={galleryData}
            path={path}
          />
        </div>
      </div>
    </>
  );
};
export default Gallery;
