import { z } from "zod";

export const curatedSchema = z.object({
  name: z.string().trim().nonempty("Name is required"),

  email: z
  .string()
  .trim()
  .nonempty({ message: "Email is required" }) 
  .email({ message: "Invalid email format" }),
  
  country_code: z
  .object({
    value: z.string().trim().nonempty("Country code is required"),
    label: z.string().optional(),
  })
  .nullable() 
  .refine((val) => val !== null, {
    message: "Country code is required",
  }),
  phone: z
    .string()
    .trim()
    .nonempty("Phone number is required")
    .regex(/^\+?[1-9]\d{5,14}$/, {
      message: "Please enter a valid phone number",
    })
    .min(6, "Phone number must be at least 6 digits")
    .max(14, "Phone number must not exceed 14 digits")
    .refine((value) => value.trim().length > 0, "Phone number cannot be blank"),

  travel_date: z.object({
    start_date: z.string().nonempty("Start date is required").trim(),
    end_date: z.string().nonempty("End date is required").trim(),
  }),
  guestData: z
    .array(
      z.object({
        key: z.string(),
        count: z.number().nonnegative(), 
      })
    )
    .refine((guestData) => guestData.reduce((sum, item) => sum + item.count, 0) > 0, {
      message: "Please select at least one guest.",
    }),
  point_of_origin: z.string().trim().nonempty("Point of origin is required"),

  comment: z.string().trim().nonempty("Comments are required"),
});

export const contactSchema = z.object({
  first_name: z
    .string()
    .min(1, "First name is required")
    .refine((value) => value.trim().length > 0, "Name cannot be just spaces"),
  last_name: z
    .string()
    .min(1, "Last Name is required")
    .refine((value) => value.trim().length > 0, "Name cannot be just spaces"),
    country_code: z
    .object({
      value: z.string().trim().nonempty("Country code is required"),
      label: z.string().optional(),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: "Country code is required",
    }),
  email: z
  .string()
  .trim()
  .nonempty({ message: "Email is required" }) 
  .email({ message: "Invalid email format" }),
  phone: z
    .string()
    .trim()
    .nonempty("Phone number is required")
    .regex(/^\+?[1-9]\d{1,14}$/, {
      message: "Please enter a valid phone number",
    })
    .min(6, "Phone number must be at least 6 digits")
    .max(14, "Phone number must not exceed 14 digits")
    .refine((value) => value.trim().length > 0, "Phone number cannot be blank"),
  comment: z
    .string()
    .min(1, "Comment is required")
    .refine((value) => value.trim().length > 0, "Comment cannot be blank"),
});

export const bookingSchema = z.object({
  first_name: z
    .string()
    .min(1, "Name is required")
    .refine((value) => value.trim().length > 0, "Name cannot be just spaces"),
  last_name: z
    .string()
    .min(1, "Name is required")
    .refine((value) => value.trim().length > 0, "Name cannot be just spaces"),
  email: z
    .string()
    .email("Invalid email address")
    .min(1, "Email is required")
    .refine((value) => value.trim().length > 0, "Email cannot be just spaces"),
    phone: z
    .string()
    .trim()
    .nonempty("Phone number is required")
    .regex(/^\+?[1-9]\d{1,14}$/, {
      message: "Please enter a valid phone number",
    })
    .min(6, "Phone number must be at least 6 digits")
    .max(14, "Phone number must not exceed 14 digits")
    .refine((value) => value.trim().length > 0, "Phone number cannot be blank"),
  country_code: z.object({
    value: z.string().trim().nonempty("Country code is required"),
    label: z.string().trim(),
  }),
  nationality: z.object({
    value: z.string().trim().nonempty("Country is required"),
    label: z.string().trim(),
  }),
  dob: z.date().refine((value) => !value?.length, "Please select date"),
});
