import React, { useEffect, useState } from "react";
import { fetchBookingProcessData } from "../../service/service";
import PolicyContent from "../../components/web/PolicyContent";

export default function BookingProcess() {
  const [bookingProcessData, setBookingProcessData] = useState({});

  useEffect(() => {
    fetchBookingProcessData((success, data) => {
      if (success) {
        setBookingProcessData(data);
      }
    });
  }, []);
  

  return <PolicyContent contentData={bookingProcessData} />;
}
