import React, { useEffect, useState } from "react";
import { fetchAboutData } from "../../service/service";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Title from "../../components/web/Title";
import MetaHeader from "../../commonComponents/MetaHeader/MetaHeader";

export default function About() {
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    fetchAboutData((success, data) => {
      if (success && data) {
        setAboutData(data);
      }
    });
  }, []);

  return (
    <>
      <MetaHeader
        title={aboutData?.meta_title}
        description={aboutData?.meta_description}
        keywords={aboutData?.meta_keyword}
      />
      <div className="about_page">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-6">
              <div className="images">
                <img
                  src={
                    aboutData?.banner_image1
                      ? `${IMAGE_BASE_URL}about/${aboutData.banner_image1}`
                      : ""
                  }
                  alt={aboutData.banner_image1_alt}
                />
                <img
                  src={
                    aboutData?.banner_image2
                      ? `${IMAGE_BASE_URL}about/${aboutData.banner_image2}`
                      : ""
                  }
                  alt={aboutData.banner_image2_alt}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h1>{aboutData.title}</h1>
                <p>{aboutData.short_description}</p>
              </div>
              <div className="features">
                {aboutData.features?.map((ele, i) => {
                  return (
                    <div className="gridItems" key={ele._id}>
                      <img
                        src={`${IMAGE_BASE_URL}about/${ele.icon}`}
                        alt={aboutData.icon_alt}
                      />
                      <h4>{ele?.title}</h4>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vision_mission">
        <div className="container-fluid pl-0 pe-0">
          <div className="row mx-0">
            <div className="col-md-6 p-0">
              <div className="visionMission">
                <div className="images">
                  <img
                    src={
                      aboutData?.mission_image
                        ? `${IMAGE_BASE_URL}about/${aboutData.mission_image}`
                        : ""
                    }
                    className="w-100"
                    alt={aboutData.mission_image_alt}
                  />
                </div>
                <div className="content">
                  <img
                    src={
                      aboutData?.mission_icon
                        ? `${IMAGE_BASE_URL}about/${aboutData.mission_icon}`
                        : ""
                    }
                    alt={aboutData.mission_icon_alt}
                  />
                  <h3>{aboutData.vision_title}</h3>
                  <p>{aboutData.vision_description}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="visionMission">
                <div className="images">
                  <img
                    src={
                      aboutData.vision_image
                        ? `${IMAGE_BASE_URL}about/${aboutData.vision_image}`
                        : ""
                    }
                    className="w-100"
                    alt={aboutData.vision_image_alt}
                  />
                </div>
                <div className="content">
                  <img
                    src={
                      aboutData?.vision_icon
                        ? `${IMAGE_BASE_URL}about/${aboutData.vision_icon}`
                        : ""
                    }
                    alt={aboutData.vision_icon_alt}
                  />
                  <h3>{aboutData.mission_title}</h3>
                  <p dang>{aboutData.mission_description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="tailor_package">
        <div className="container">
          <Title title={aboutData.main_title} />
          <p
            data-aos="fade-up"
            data-aos-duration="3000"
            dangerouslySetInnerHTML={{ __html: aboutData.main_description }}
          ></p>
        </div>
        <div className="container-fluid p-0">
          <div className="row mx-0">
            <img
              src={
                aboutData?.main_image
                  ? `${IMAGE_BASE_URL}about/${aboutData.main_image}`
                  : ""
              }
              alt={aboutData.main_image_alt}
            />
          </div>
        </div>
      </section>
    </>
  );
}
