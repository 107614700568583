import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  countryDetails,
  fetchContactData,
  submitContact,
} from "../../service/service";
import { contactSchema } from "../../validation/validation";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { endLoading, startLoading } from "../../redux/commonReducer";
import { Spinner } from "react-bootstrap";
import MetaHeader from "../../commonComponents/MetaHeader/MetaHeader";

export default function Contact() {
  const [contactData, setContactData] = useState({});
  const [countryCode, setCountryCode] = useState();
  const loader = useSelector((state) => state?.commonReducer?.isLoading);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(contactSchema),
  });

  useEffect(() => {
    fetchContactData((success, data) => {
      if (success && data) {
        setContactData(data);
      }
    });
  }, []);

  useEffect(() => {
    countryDetails((success, data) => {
      if (success && data) {
        setCountryCode(data);
      }
    });
  }, []);

  const onSubmit = (data) => {
    let contactData = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      country_code: data.country_code.value,
      email: data.email,
      enquiry_type: "contact",
      comment: data.comment,
    };

    dispatch(startLoading());
    submitContact(contactData, (res) => {
      if (res.status) {
        dispatch(endLoading());
        reset();
        reset({
          country_code: null,
        });
        toast.success(res.message);
      } else {
        dispatch(endLoading());
        toast.error(res.message || "Submission failed");
      }
    });
  };

  return (
    <>
      <MetaHeader
        title={contactData?.meta_title}
        description={contactData?.meta_description}
        keywords={contactData?.meta_keyword}
      />
      <div className="skyward__contact">
        <img
          src={
            contactData.image ? `${IMAGE_BASE_URL}cms/${contactData.image}` : ""
          }
          className="w-100"
          alt={contactData.image_alt}
        />
      </div>
      <div className="skyward__contact_form">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 col-tab-12">
              <div
                className="contact_us"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Contact Us</h2>
                <div className="Info">
                  <div className="item">
                    <div className="icon">
                      <img src="assets/img/icons/email.svg" alt="" />
                    </div>
                    <h4>Email</h4>
                    <p>{contactData.short_description1}</p>
                    <a href={`mailto:${contactData.email}`}>
                      {contactData.email}
                    </a>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <img src="assets/img/icons/phone.svg" alt="" />
                    </div>
                    <h4>Phone</h4>
                    <p>{contactData.short_description2}</p>
                    <a href={`tel:${contactData?.phone}`}>
                      {contactData?.phone}
                    </a>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <img src="assets/img/icons/location.svg" alt="" />
                    </div>
                    <h4>{contactData?.address_title1}</h4>
                    <h5 style={{ whiteSpace: "pre-wrap" }}>
                      {contactData?.address1}
                    </h5>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <img src="assets/img/icons/location.svg" alt="" />
                    </div>
                    <h4>{contactData?.address_title2}</h4>
                    <h5 style={{ whiteSpace: "pre-wrap" }}>
                      {contactData?.address2}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-tab-12">
              <div
                className="getintouch"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2>{contactData?.getintouch_title}</h2>
                <h5>{contactData?.getintouch_tagline}</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          className={`form-control ${
                            errors.first_name ? "is-invalid" : ""
                          }`}
                          {...register("first_name")}
                        />
                        {errors.first_name && (
                          <p className="text-danger">
                            {errors.first_name.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          className={`form-control ${
                            errors.last_name ? "is-invalid" : ""
                          }`}
                          {...register("last_name")}
                        />
                        {errors.last_name && (
                          <p className="text-danger">
                            {errors.last_name.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group country_list">
                        <label htmlFor="countryCode">Country Code</label>
                        <Controller
                          name="country_code"
                          control={control}
                          rules={{ required: "Country code is required" }}
                          render={({ field, fieldState: { error } }) => (
                            <div>
                              <Select
                                {...field}
                                options={countryCode?.map((item) => ({
                                  label: `${item?.dial_code}`,
                                  value: item?.dial_code,
                                }))}
                                placeholder="Code"
                                isClearable
                                onChange={(selectedOption) =>
                                  field.onChange(selectedOption || null)
                                }
                                value={field.value || null}
                              />
                            </div>
                          )}
                        />
                        {errors.country_code && (
                          <span className="text-danger">
                            {errors.country_code.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                          type="number"
                          placeholder="Enter Your Phone Number"
                          className={`form-control ${
                            errors?.phone ? "is-invalid" : ""
                          }`}
                          {...register("phone")}
                          min={1}
                        />
                        {errors?.phone && (
                          <p className="text-danger">{errors?.phone.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          placeholder="Enter Your Email"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          {...register("email")}
                        />
                        {errors?.email && (
                          <p className="text-danger">{errors?.email.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="comment">Comment</label>
                        <textarea
                          placeholder="Enter Your Comment"
                          className={`form-control ${
                            errors.comment ? "is-invalid" : ""
                          }`}
                          rows={4}
                          {...register("comment")}
                        ></textarea>
                        {errors?.comment && (
                          <p className="text-danger">
                            {errors.comment.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <p>{contactData?.footer_info_text}</p>
                    </div>

                    <div>
                      <button type="submit" className="submit">
                        {loader ? (
                          <Spinner animation="border" />
                        ) : (
                          <>
                            Submit
                            <img
                              src="/assets/img/icons/right.svg"
                              height="30"
                              alt="right arrow icon"
                            />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
