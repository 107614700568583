import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

export default function Activities({ activity, activityName }) {
  return (
    <div className="attraction">
      <div className="container">
        <div className="header">
          <h2 className="title">Activities {`- ${activityName}`}</h2>
        </div>
        <div className="row">
          {activity?.map((ele, i) => {
            return (
              <div className="col-md-3" key={i}>
                <Link to={`/activity-details/${ele?.handle_key}`}>
                  <div className="atraction">
                    <div className="image">
                      <img
                        src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                        alt={ele.image_alt}
                        className="w-100"
                      />
                    </div>
                    <div className="contents">
                      <h3>{ele?.title}</h3>
                      <p>
                        <img
                          src="/assets/img/icons/clock.svg"
                          height={17}
                          alt=""
                        />
                        Duration {ele?.duration} hours
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
