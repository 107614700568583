import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "./redux/commonReducer";
import adminReducer from "./redux/adminReducer";

export default configureStore({
  reducer: {
    commonReducer: commonReducer,
    adminReducer : adminReducer,
  },
});
