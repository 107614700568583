import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "adminReducer",
  initialState: {
    value: true,
  },
  reducers: {
    updateAdminRedux: (state, data) => {
      state[data.payload.key] = data.payload.value;
    },
  },
});
export const { updateAdminRedux } = adminSlice.actions;

export const fileUploadLoader = (state ) => state.adminReducer.fileUploadLoader;

export const customer_engagement_details = (state ) => state.adminReducer.customer_engagement_details;
export const customer_engagement_details_loading = (state ) => state.adminReducer.customer_engagement_details_loading;
export const update_traveler_loader = (state ) => state.adminReducer.update_traveler_loader;

export const customer_itinerary_loading = (state ) => state.adminReducer.customer_itinerary_loading;
export const customer_itinerary_details = (state ) => state.adminReducer.customer_itinerary_details;

export const booking_payment_loading = (state ) => state.adminReducer.booking_payment_loading;
export const booking_payment_details = (state ) => state.adminReducer.booking_payment_details;

export const booking_status = (state ) => state.adminReducer.booking_status;
export const accounts_details = (state ) => state.adminReducer.accounts_details;

export const update_review_loader = (state ) => state.adminReducer.update_review_loader;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;
// export const value = (state ) => state.adminReducer.value;


export default adminSlice.reducer;
