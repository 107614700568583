import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

export default function Itinerary({
  details,
  path,
  attractionHandler,
  activityHandler,
}) {
  const [mainMap, setMainMap] = useState("");
  const [mainMapAlt, setMainMapAlt] = useState("");
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    setMainMap(details?.main_map);
    setMainMapAlt(details?.main_map_alt);
  }, [details]);

  const changeImage = (ele) => {
    setMainMap(ele?.map_image);
    setMainMapAlt(ele?.map_image_alt);
  };

  const handleAccordionClick = (i, ele) => {
    setActiveKey(activeKey === i ? null : i);
    changeImage(ele);
  };

  return (
    <div className="itinerary">
      <div className="container">
        <h2 className="title">ITINERARY</h2>
        {mainMap?.length ? (
          <img
            src={`${IMAGE_BASE_URL}${path}/${mainMap}`}
            alt={mainMapAlt}
            className="w-100"
          />
        ) : (
          <></>
        )}

        <div className="trip_schedules">
          <Accordion activeKey={activeKey}>
            {details?.itinerary?.map((ele, i) => {
              return (
                <Accordion.Item eventKey={i}>
                  <Accordion.Header
                    onClick={() => handleAccordionClick(i, ele)}
                  >
                    Day {i + 1} :{ele?.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="collapse__content">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: ele?.description,
                        }}
                      ></p>
                      <div className="gridLists">
                        {ele?.attraction?.length ? (
                          <div
                            className="item"
                            onClick={() => attractionHandler(ele, i)}
                          >
                            <img
                              src="/assets/img/icons/entertainment.svg"
                              alt=""
                            />
                            <p>Attractions</p>
                          </div>
                        ) : (
                          <></>
                        )}
                        {ele?.activity?.length ? (
                          <div
                            className="item"
                            onClick={() => activityHandler(ele, i)}
                          >
                            <img
                              src="/assets/img/icons/entertainment.svg"
                              alt=""
                            />
                            <p>Activities</p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
