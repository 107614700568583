import axios from "axios";
import { API_BASE_URL } from "../constants/configuration";
import { errorToast } from "../redux/commonActionCreator";

const defaultOptions = {
  baseURL: API_BASE_URL,
};

// axios instance for making requests
const axiosInstance = axios.create(defaultOptions);

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  let csrf = localStorage.getItem("csrf");
  token && (config.headers.Authorization = `Bearer ${token}`);
  csrf && (config.headers["x-csrf-token"] = csrf);
  return config;
});

axiosInstance.interceptors.response.use(
  (response, dispatch) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      if (error) {
        errorToast(error.message);
      }
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    console.log(error.response,"error.response.data.token")
    if (error.response.data.token == false || error.response.data.message == "Missing token") {
      localStorage.removeItem("token");
      localStorage.removeItem("csrf");
      window.location.hash = "/manage-booking";
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
