import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  currencyValue,
  non_relevant_package_data,
  package_data,
  package_path,
  searchData,
  searchGuestData,
  token_payment,
  updateRedux,
} from "../../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import {
  getNonRelevantPackagesList,
  getPackages,
} from "../../../redux/commonActionCreator";
import PackageList from "../PackageList";
import moment from "moment";
import HomeSearch from "../../../components/web/HomeSearch";
import StarRatings from "react-star-ratings";
import DynamicFontSize from "../../../components/web/DynamicFontSize";
import { CurrencyConversion } from "../../../utils/helpers";

export default function SearchTours() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const packageData = useSelector(package_data);
  const path = useSelector(package_path);
  const nonRelevantData = useSelector(non_relevant_package_data);
  const tokenPayment = useSelector(token_payment);
  const selectedCurrency = useSelector(currencyValue);
  const dataSearch = useSelector(searchData);
  const guestData = useSelector(searchGuestData);

  const [data, setData] = useState({
    page: 0,
    perPage: 8,
  });

  useEffect(() => {
    console.log(dataSearch, "5");
    if (
      dataSearch?.departure == "" &&
      dataSearch?.start_date == "" &&
      dataSearch?.end_date == ""
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getSearchPackageDataHandler();
  }, []);

  const getSearchPackageDataHandler = () => {
    let rawData = {
      id: dataSearch?.departure?._id ?? "",
      start_date: dataSearch?.start_date
        ? moment(dataSearch?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: dataSearch?.end_date
        ? moment(dataSearch?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: guestData?.find((ele) => ele.key === "adults")?.count,
        age_5_12: guestData?.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: guestData?.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: guestData?.find((ele) => ele.key === "age_less_2")?.count,
      },
    };
    dispatch(
      getPackages(rawData, () => {
        getSearchDataHandler(data);
      })
    );
  };

  const getSearchDataHandler = (filter) => {
    let rawData = {
      page: filter.page,
      perPage: filter.perPage,
      id: dataSearch?.departure?._id ?? "",
      start_date: dataSearch?.start_date
        ? moment(dataSearch?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: dataSearch?.end_date
        ? moment(dataSearch?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: guestData.find((ele) => ele.key === "adults")?.count,
        age_5_12: guestData.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: guestData.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: guestData.find((ele) => ele.key === "age_less_2")?.count,
      },
    };
    dispatch(getNonRelevantPackagesList(rawData));
  };

  const handlePageChange = () => {
    let temp;
    temp = {
      page: data?.page,
      perPage: data?.perPage + 4,
    };
    setData({
      ...temp,
    });
    getSearchDataHandler(temp);
  };

  return (
    <>
      <section className="skyward__selfDrive pt-0">
        <img src="/assets/img/self-drive.jpg" className="w-100 main" alt="" />
        <div className="search__contents">
          <h1>Self-drive tours</h1>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde eius
            vel facilis nam perspiciatis id dolorum in, doloribus dolore? Iste
            molestiae veniam quis. Numquam libero earum cum.
          </p>
          <HomeSearch />
        </div>
      </section>

      <div className="refined_search">
        <div className="container">
          <h2>
            We've Refined Your Search Results. Explore these iceland packages
            that fir your specific needs
          </h2>
        </div>
      </div>
      {packageData?.length ? (
        <section className="recommended__packages">
          <div className="container">
            <h2>RECOMMENDED packages</h2>
          </div>
          <div className="tour_packages scroll">
            {packageData?.map((ele, i) => {
              return (
                <div className="trips" key={i}>
                  <div className="image">
                    <img
                      src={`${IMAGE_BASE_URL}${path}/${ele?.thumbnail}`}
                      alt={ele.thumbnail_alt}
                      className="w-100"
                    />
                    <div className="tag">
                      {i === 0 ? "HIGHLY recommended" : "Recommended"}
                    </div>
                  </div>
                  <div className="details">
                    <h3>{`${ele?.package_name}`}</h3>
                    <div className="package_features">
                      {ele?.averageRating ? (
                        <>
                          <div className="lists">
                            <h3>{ele?.averageRating}/5</h3>
                            <h6>rating</h6>
                          </div>
                          <div className="lists">
                            <span
                              className="d-flex"
                              style={{ pointerEvents: "none" }}
                            >
                              <StarRatings
                                rating={Number(ele?.averageRating)}
                                starRatedColor="#dc1e35"
                                numberOfStars={5}
                                starEmptyColor="#e3e3e3"
                                name="rating"
                                starDimension="22px"
                                starSpacing="0px"
                              />
                            </span>
                            <h6>( {ele?.totalReviews} REVIEW )</h6>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="lists">
                        <div className="icon">
                          <img src="assets/img/icons/drive.svg" alt="" />
                        </div>
                        <h6>Self drive</h6>
                      </div>
                      <div className="lists">
                        <h3>{`${ele?.duration}`}</h3>
                        <h6>Days</h6>
                      </div>
                      <div className="lists">
                        <div className="icon">
                          <img
                            src={`${IMAGE_BASE_URL}${ele?.season?.path}/${ele?.season?.icon}`}
                            alt={ele.season?.icon_alt}
                          />
                        </div>
                        <h6>{ele.season?.name}</h6>
                      </div>
                      <div className="lists">
                        <div className="icon">
                          <img src="assets/img/icons/date-from.svg" alt="" />
                        </div>
                        <h6>{`${ele?.start_month} - ${ele?.end_month}`}</h6>
                      </div>
                      <div className="lists">
                        <div className="icon">
                          <img src="assets/img/icons/km.svg" alt="" />
                        </div>
                        {/* <h6>2110/ 1144 km/mi</h6> */}

                        <h6>{ele.klm} KM</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 pe-0">
                        <div className="price_details">
                          <ul>
                            <li>
                              <img src="/assets/img/icons/hotel.svg" alt="" />{" "}
                              Premier Hotel Stays including Breakfasts
                            </li>
                            <li>
                              <img
                                src="/assets/img/icons/car-rent.svg"
                                alt=""
                              />{" "}
                              Car-Rentals{" "}
                            </li>
                            <li>
                              <img
                                src="/assets/img/icons/entertainment.svg"
                                alt=""
                              />{" "}
                              Entertainment activities including spa
                            </li>
                            <li>
                              <img
                                src="/assets/img/icons/insurance.svg"
                                alt=""
                              />{" "}
                              Car & Travel Insurance
                            </li>
                            <li>
                              <img
                                src="/assets/img/icons/customise.svg"
                                alt=""
                              />{" "}
                              Engagement session to customise travel experience.
                            </li>
                            <li>
                              <img src="/assets/img/icons/support.svg" alt="" />{" "}
                              Live support during Travel
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 ps-0">
                        <div className="price_details br-0">
                          <h6>Price per person</h6>
                          <h5>From</h5>
                          <h2>
                            {/* {ele?.adult_cost} <span>USD</span> */}
                            <DynamicFontSize
                              number={
                                CurrencyConversion(
                                  selectedCurrency,
                                  ele?.adult_cost
                                ) ?? ele?.adult_cost
                              }
                            />

                            {/* For the currency code */}
                            <DynamicFontSize
                              number={selectedCurrency?.currency_code ?? "USD"}
                              tag="h5"
                              className="mb-0"
                            />
                          </h2>
                          {/* <h3>Total of {ele?.estimate} USD</h3> */}
                          <h3>
                            Total of{" "}
                            {CurrencyConversion(
                              selectedCurrency,
                              ele?.estimate
                            ) ?? ele?.estimate}{" "}
                            {selectedCurrency?.currency_code ?? "USD"}
                          </h3>
                          <p>Exclusive of flight cost</p>
                          <div className="reserve">
                            <img src="/assets/img/icons/reserve.svg" alt="" />
                            Reserve now for just ${tokenPayment} and pay the
                            rest later!
                          </div>
                        </div>
                        <Link to={`/package-details/${ele?.handle_key}`}>
                          <div className="view_trip">
                            view trip
                            <img
                              src="/assets/img/icons/view-tour-arrow.svg"
                              alt=""
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      ) : (
        <p className="d-flex justify-content-center">No result found based on your search</p>
      )}

      {nonRelevantData?.length ? (
        <section className="explore__packages">
          <div className="container">
            <h2>EXPLORE PACKAGES</h2>
            <div className="row">
              <PackageList packageData={nonRelevantData} />
            </div>
            {/* {nonRelevantData?.length ? (
              <div className="button_center">
                <button
                  className="btn btn-primary"
                  onClick={() => handlePageChange()}
                >
                  VIEW ALL{" "}
                  <img src="assets/img/icons/button-arrow.svg" alt="" />
                </button>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
