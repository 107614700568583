import React from "react";
import "./payment.css";
import { Modal } from "react-bootstrap";
import moment from "moment";

const PaymentSuccess = ({ handleClose, paymentModal, paymentValues }) => {
  return (
    <Modal show={paymentModal} centered onHide={handleClose} size="lg">
      <section id="payment">
        <div className="container px-4">
          <div className="row">
            <div className="col-12 py-3">
              <div className="payment_card">
                <div className="card-body py-5 px-4">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-5 d-flex justify-content-center mb-4 mb-md-0">
                      <div className="payment_success_imagecontainer">
                        <img
                          src="/assets/img/admin/payment-success.png"
                          className="w-100"
                          alt="Payment Success"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-7">
                      <div className="payment_success_details">
                        <h1>
                          Payment <br /> Successful!
                        </h1>
                        <p>
                          Your payment of{" "}
                          <span>{paymentValues?.amount_total} USD</span> was
                          successful! <br />
                          {/* You will receive an email confirming your reservation
                          details <br />
                          shortly! */}
                        </p>
                      </div>
                      <div className="d-flex flex-wrap align-items-center gap-2">
                        {/* <div className="payment_ids">
                          <p>Booking ID: 3223243231</p>
                        </div> */}
                        <div className="payment_ids">
                          <p>Transaction ID: {paymentValues?.payment_id}</p>
                        </div>
                      </div>
                      <div className="payment_date">
                        <p>
                          {moment(new Date()).format("DD MMMM YYYY | hh:mm a")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default PaymentSuccess;
