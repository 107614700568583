import { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsDetails,
  getBookingStatus,
} from "../../redux/adminActionCreator";
import { booking_status } from "../../redux/adminReducer";

const Header = () => {
  const dispatch = useDispatch();

  const details = localStorage.getItem("booking_details") ? JSON.parse(localStorage.getItem("booking_details")) : {};
  const bookingId = localStorage.getItem("booking_id");

  const bookingStatus = useSelector(booking_status) ?? {};
  const [displayMsg, setDisplayMsg] = useState("");

  useEffect(() => {
    dispatch(getAccountsDetails());
  }, []);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingStatus(bookingId));
    }
  }, [bookingId]);

  useEffect(() => {
    if (bookingStatus["is_passenger_info"]) {
      setDisplayMsg(
        `Please update passenger details to <br /> proceed with your booking.`
      );
    }
    if (bookingStatus["is_customer_engagement"]) {
      setDisplayMsg(`Please review customer engagement section.`);
    }
    if (bookingStatus["is_quotation"]) {
      setDisplayMsg(`Your final, personalized quote will be <br /> shared with you once we've
      completed <br /> our customer engagement process.`);
    }
    if (bookingStatus["is_payment"]) {
      setDisplayMsg(`Your payment under processing.`);
    }
  }, [bookingStatus]);

  return (
    <header className="header">
      <div className="menu-icon">
        <RxHamburgerMenu className="icon" />
      </div>
      <div className="header-left">
        <h2>Welcome, {details?.traveller_info?.[0]?.first_name}!</h2>
      </div>
      <div className="header-middle">
        <img src="/assets/img/admin/info.svg" alt="" />
        <h4 dangerouslySetInnerHTML={{ __html: displayMsg }}></h4>
      </div>
      <div className="header-right">
        <h1>
          BOOKING NO <br />
          <span>{details?.booking_ref_id}</span>
        </h1>
      </div>
    </header>
  );
};

export default Header;
