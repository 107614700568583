import React, { useEffect, useState } from "react";
import { fetchPrivacyPolicy } from "../../service/service";
import PolicyContent from "../../components/web/PolicyContent";

const PrivacyPolicies = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);

  useEffect(() => {
    fetchPrivacyPolicy((success, data) => {
      if (success) {
        console.log(data)
        setPrivacyPolicyData(data);
      }
    });
  }, []);

  return <PolicyContent contentData={privacyPolicyData} />;
};

export default PrivacyPolicies;
