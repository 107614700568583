import React from "react";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import { useNavigate } from "react-router-dom";

export default function Blogs({ details }) {
  const navigate = useNavigate();
  return (
    <div className="blogs">
      <div className="container">
        <h2 className="title">Related blogs</h2>
        <div className="row">
          {details?.blog?.map((ele, i) => {
            return (
              <div className="col-md-4" key={i}>
                <div
                  className="blogGrid"
                  onClick={() => navigate(`/blogs-details/${ele?.handle_key}`)}
                >
                  <img
                    src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.thumbnail}`}
                    alt={ele.image_alt}
                    className="w-100"
                  />
                  <h4>{ele?.title}</h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
