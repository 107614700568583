import React, { useEffect } from "react";
import "./engagement.css";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerEngagementDetails } from "../../../redux/adminActionCreator";
import {
  accounts_details,
  customer_engagement_details,
  customer_engagement_details_loading,
} from "../../../redux/adminReducer";
import { Form } from "react-bootstrap";
import LoadingSpinner from "../../../commonComponents/LoadingSpinner/LoadingSpinner";
import moment from "moment";

const EngagementDeatils = () => {
  const dispatch = useDispatch();
  const bookingId = localStorage.getItem("booking_id");

  const details = useSelector(customer_engagement_details);
  const loading = useSelector(customer_engagement_details_loading);
  const accountDetails = useSelector(accounts_details);

  useEffect(() => {
    if (bookingId) {
      dispatch(getCustomerEngagementDetails(bookingId));
    }
  }, [bookingId]);

  return (
    <section id="engagment">
      <div className="container px-3 ">
        <div className="row">
          <div className="col-12 py-3 ">
            <div class="card">
              <div class="card-body ">
                <div className="primary_txt p-4">
                  <h4>Customer Engagement Summary</h4>
                  <p>
                    Based on ourr recent conversation, here's a summary of your
                    preference in Iceland trip...
                  </p>
                </div>

                <div className="row secondary_txt p-4 ">
                  <div className="col-12 col-md-5">
                    <h4>Engagement Details</h4>
                    <p>
                      Conversation Date :{" "}
                      {moment(details?.created_date).format("DD MMMM YYYY")}
                    </p>
                  </div>
                  <div className="col-12 col-md-7 ">
                    <h4>Customer Representative</h4>
                    <p>{details?.customer_rep?.name ?? "Admin"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          {details?.questionnaire_section.map((item, index) => {
            return (
              <div className="container px-3 py-2">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row primary_border align-items-center">
                        <div className="col-6 col-md-1 py-2  d-flex align-items-center justify-content-center ">
                          <div className="quesion_count">
                            <p>{index + 1}</p>
                          </div>
                        </div>
                        <div className="col-6 col-md-9 py-2 ">
                          <div className="question-content px-2">
                            <h5>Question</h5>
                            <p>{item?.question_id?.title}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-2 py-2">
                          <div
                            className={`answer_button ${
                              item?.answer
                                ? "answer_button_true"
                                : "answer_button_false"
                            }`}
                          >
                            <p>Your Answer</p>
                            <div
                              className={`status_toggle ${
                                item?.answer ? "yes" : "no"
                              }`}
                            >
                              <div className="answer_status">
                                {item?.answer ? (
                                  <p>
                                    <img
                                      src="/assets/img/icons/check-white.svg"
                                      alt=""
                                    />{" "}
                                    Yes
                                  </p>
                                ) : (
                                  <p>
                                    {" "}
                                    <img
                                      src="/assets/img/icons/exclude-check.svg"
                                      alt=""
                                    />{" "}
                                    No
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item?.comment ? (
                        <div className="comments_description">
                          <h6>Comments</h6>
                          <p>{item?.comment}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="container px-3">
            <div className="row">
              <div className="col-12 py-3 ">
                <div class="card px-3">
                  <div class="card-body ">
                    <div className="additional_information">
                      <h4>Additional Information</h4>
                      <p
                        className="additional_description"
                        dangerouslySetInnerHTML={{
                          __html:
                            details?.additional_info	,
                        }}
                      ></p>
                      <h6>The Choosen Payment</h6>
                      <p className="choose_payment">
                        {details?.payment_method === "direct"
                          ? "Direct Bank"
                          : "Online Payment"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default EngagementDeatils;
