import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import { updateRedux } from "./commonReducer";

export function successToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}

export function errorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}

export const getPackages = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "package_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getPackages`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "package_loader", value: false }));
      if (res.data.status) {	
        dispatch(updateRedux({ key: "package_data", value: res.data.data }));
        dispatch(updateRedux({ key: "package_page_details", value: res.data.page }));
        dispatch(updateRedux({ key: "package_path", value: res.data.path }));
        dispatch(updateRedux({ key: "token_payment", value: res.data.token_payment }));
        if(callback){
          callback()
        }
      }
      //  else {
      //   toast.error(res.data.message, {
      //     position: "bottom-center",
      //     autoClose: 3000,
      //   });
      // }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "package_loader", value: false }));
      console.log(err);
    });
};

export const getAirportCodes = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getAirportCodes`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "airport_codes", value: res.data.data }));
      }
      //  else {
      //   toast.error(res.data.message, {
      //     position: "bottom-center",
      //     autoClose: 3000,
      //   });
      // }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getNonRelevantPackagesList = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getNonRelevantPackagesList`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "non_relevant_package_data", value: res.data.data }));
      } 
      // else {
      //   toast.error(res.data.message, {
      //     position: "bottom-center",
      //     autoClose: 3000,
      //   });
      // }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPackageDetail = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "package_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getPackageDetail`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "package_loader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "package_details", value: res.data.data[0] }));
        dispatch(updateRedux({ key: "package_path", value: res.data.path }));
        localStorage.setItem("package_details", JSON.stringify(res.data.data[0]));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "package_loader", value: false }));
      console.log(err);
    });
};

export const getCurrency = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getCurrency`)
    .then((res) => {
      if (res.data.status) {
        // let usd = res.data.data?.filter((ele, i) => ele?.handle_key === "usd")[0];
        let usdValue = {
          // ...usd,
          conversion_rate : "1",
          currency_code	: "USD",
          handle_key	: "usd",
          is_website_display : true,
          is_deleted : false
        }
        let other = res.data.data?.filter((ele, i) => ele?.handle_key !== "usd");
        let arr = [];
        arr = [usdValue, ...other];
        dispatch(updateRedux({ key: "currency_data", value: arr }));
        localStorage.setItem("currency_data", JSON.stringify( arr));
      } 
      // else {
      //   toast.error(res.data.message, {
      //     position: "bottom-center",
      //     autoClose: 3000,
      //   });
      // }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getBlogDetails = (id, callback) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}blogs/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "blog_details", value: res.data.data }));
        dispatch(updateRedux({ key: "blog_path", value: res.data.path }));
      } 
      else {
        // toast.error(res.data.message, {
        //   position: "bottom-center",
        //   autoClose: 3000,
        // });
        callback && callback()
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAttractionDetails = (id) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}attraction/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "attraction_details", value: res.data.data }));
        dispatch(updateRedux({ key: "attraction_path", value: res.data.path }));
      } 
      // else {
      //   toast.error(res.data.message, {
      //     position: "bottom-center",
      //     autoClose: 3000,
      //   });
      // }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getActivityDetails = (id) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}activity/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "activity_details", value: res.data.data }));
        dispatch(updateRedux({ key: "activity_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getRoundTripFlights = (data) => (dispatch) => {
  dispatch(updateRedux({ key: "flightSearchLoader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getRoundTripFlights`,data)
    .then((res) => {
      dispatch(updateRedux({ key: "flightSearchLoader", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "flight_list", value: res.data.data }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "flightSearchLoader", value: false }));
      console.log(err);
    });
};

export const getCountry = (data) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getCountry`,data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "country_list", value: res.data.data }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendOTP = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "send_otp_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}sendOTP`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "send_otp_loader", value: false }));
      if (res.data.status) {
        if(callback){
          callback()
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "send_otp_loader", value: false }));
      console.log(err);
    });
};

export const validateOtp = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}validateOtp`, data)
    .then((res) => {
      if (res.data.status) {
        localStorage.setItem("otp_verified", true);
        dispatch(updateRedux({ key: "otp_verified", value: true }));
        if(callback){
          callback()
        }
      } else {
        localStorage.setItem("otp_verified", false);
        dispatch(updateRedux({ key: "otp_verified", value: false }));
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const submitBooking = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}submitBooking`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "booking_completed", value: true }));
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if(callback){
          callback(res.data)
        }
      } else {
        dispatch(updateRedux({ key: "booking_completed", value: false }));
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if(callback){
          callback(res.data)
        }
      }
    })
    .catch((err) => {
      // localStorage.setItem("otp_verified", false);
      console.log(err);
    });
};

export const verifyPromotionCode = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "promo_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}verifyPromotionCode`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "promo_loader", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if(callback) callback(res.data)
      } else {
        dispatch(updateRedux({ key: "promo_code_applied", value: false }));
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if(callback) callback(res.data)
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "promo_loader", value: false }));
      console.log(err);
    });
};

export const getBookingDetails = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "booking_details_loading", value: true}));
  axiosInstance
    .get(`${API_BASE_URL}getBookingDetails/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "booking_details_loading", value: false}));
      if (res.data.status) {
        if(res.data.booking_details?.length){
          dispatch(updateRedux({ key: "booking_details", value: res.data.booking_details[0] }));
          localStorage.setItem("booking_details",JSON.stringify(res.data.booking_details[0]));
        }else{
          localStorage.removeItem("booking_details")
        }
        
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "booking_details_loading", value: false}));
      console.log(err);
    });
};

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "login_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}login`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "login_loader", value: false }));
      if (res.data.status) {
        if(callback){
          callback(res.data)
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if(callback){
          callback(res.data)
        }
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "login_loader", value: false }));
      console.log(err);
    });
};

export const packageUserLoginVerify = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}packageUserLoginVerify`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "login_otp_verified", value: true }));
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem('csrf', res.data.csrfToken)
        localStorage.setItem('booking_id', res.data.booking_id)
        if(callback){
          callback()
        }
      } else {
        dispatch(updateRedux({ key: "login_otp_verified", value: false }));
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const retrievePaymentSession = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}retrievePaymentSession`, data)
    .then((res) => {
      if (res.data.payment_status	== "paid") {
        if(callback) callback(res.data)
      } else {
        dispatch(updateRedux({ key: "payment_process", value: false }));
        dispatch(updateRedux({ key: "book_now_loader", value: false }));
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getFlightCostDetails = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getFlightCostDetails`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "package_side_payment_details", value: res.data.data }));
      } 
    })
    .catch((err) => {
      console.log(err);
    });
};

export const removePromocode = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "promo_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}removePromocode`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "promo_loader", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if(callback) callback(res.data)
      } else {
        dispatch(updateRedux({ key: "promo_code_applied", value: false }));
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "promo_loader", value: false }));
      console.log(err);
    });
};

export const contact = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}contact`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "contact_details", value: res.data.data }));
      } 
    })
    .catch((err) => {
      console.log(err);
    });
};

export const general = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}general`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "general_details", value: res.data.data }));
        dispatch(updateRedux({ key: "general_path", value: res.data.path }));
      } 
    })
    .catch((err) => {
      console.log(err);
    });
};

export const packageCommonData = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}package-page`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "package_common_details", value: res.data.data }));
        dispatch(updateRedux({ key: "package_common_path", value: res.data.path }));
      } 
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPackageReviews = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "review_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}getPackageReviews`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "review_loader", value: false }));
      if (res.data.status) {	
        dispatch(updateRedux({ key: "review_data", value: res.data.data }));
        dispatch(updateRedux({ key: "review_path", value: res.data.path }));
        if(callback){
          callback()
        }
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "review_loader", value: false }));
      console.log(err);
    });
};

