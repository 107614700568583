import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share"

import { fetchBlogData } from "../../service/service";
import { CURRENT_URL, IMAGE_BASE_URL } from "../../constants/configuration";
import Title from "../../components/web/Title";

export default function Blogs() {
  const [blogData, setBlogData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(6);
  const [blogDescription, setBlogDescription] = useState();
  const [page, setPage] = useState(0);
  const perPage = 6;

  useEffect(() => {
    const raw = {
      page,
      perPage,
    };
    fetchBlogData(raw, (success, data) => {
      if (success && data) {
        setBlogData((prevData) =>
          page === 0 ? data.data : [...prevData, ...data.data]
        );
        setBlogDescription(data.BlogDetail);
      }
    });
  }, [page]);

  const handleViewMore = () => {
    setPage((prevPage) => prevPage + 1);
    setVisibleCount((prevCount) => prevCount + 6);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + " ......";
    }
    return text;
  };

  return (
    <>
      <section className="skyward__featured_blogs pt-0">
        <img
          src={blogData[0] ? `${IMAGE_BASE_URL}blog/${blogData[0]?.thumbnail}` : ""}
          className="w-100"
          alt={blogData[0]?.thumbnail_alt || "Featured Blog"}
        />
        <div className="container">
          <div className="featured" data-aos="fade-up" data-aos-duration="2000">
            <h1>{blogData[0]?.title}</h1>
            <div className="date">
              <p>
                {blogData[0]?.publish_date
                  ? formatDate(blogData[0]?.publish_date)
                  : ""}
              </p>
              <Link to={`/blogs-details/${blogData[0]?.handle_key}`}>
                Read More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="latest__blogs">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <Title
                title={blogDescription?.blog_title}
                desc={blogDescription?.blog_tagline}
              />
            </div>
          </div>
          <div className="row">
            {blogData?.slice(1).map((ele, i) => {
              const rawText = stripHtmlTags(ele?.description1 || "");
              const truncatedDescription = truncateText(rawText, 32);

              return (
                <div className="col-md-6" key={ele?._id}>
                  <div
                    className="blog__lists"
                    data-aos="fade-up"
                    data-aos-duration={`${(i + 1) * 1000}`}
                  >
                    <Link to={`/blogs-details/${ele?.handle_key}`}>
                      <div className="image">
                        <img
                          src={
                            ele.thumbnail
                              ? `${IMAGE_BASE_URL}blog/${ele?.thumbnail}`
                              : ""
                          }
                          className="w-100"
                          alt={ele?.thumbnail_alt || "Blog Thumbnail"}
                        />
                      </div>
                    </Link>
                    <div className="share">
                      <p>{formatDate(ele?.publish_date)}</p>
                      <div className="socials">
                        <p>Share</p>
                        <ul>
                          <li>
                            <FacebookShareButton
                              url={`${CURRENT_URL}+ "guide_details/" + ${ele?.slug}`}
                            >
                              <img
                                src="assets/img/icons/fb-share.svg"
                                alt="Share on Twitter"
                              />
                            </FacebookShareButton>

                          </li>
                          <li>
                            <TwitterShareButton
                              url={`${CURRENT_URL + "guide_details/" + ele?.slug}`}
                            >
                              <img
                                src="assets/img/icons/x-share.svg"
                                alt="Share on Twitter"
                              />
                            </TwitterShareButton>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="assets/img/icons/instagram-share.svg"
                                alt="Share on Instagram"
                              />
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <h2>{ele?.title}</h2>
                    <p>{truncatedDescription}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {visibleCount < blogData.length && (
            <div className="button_center">
              <button onClick={handleViewMore} className="btn btn-primary">
                VIEW MORE{" "}
                <img
                  src="assets/img/icons/button-arrow.svg"
                  alt="Button Arrow"
                />
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
