import React from "react";

export default function IncludeExclude({ details }) {
  return (
    <>
      <div className="include__exclude">
        <div className="container">
          <h3>WHAT'S INCLUDED AND EXCLUDED</h3>
        </div>
      </div>
      <div className="include_exclude_items">
        <div className="container">
          <div className="row">
            {details?.inclusions?.length ? (
              <div className="col-md-6">
                <h4>Included</h4>
                <ul>
                  {details?.inclusions?.map((ele) => {
                    return (
                      <li>
                        <img src="/assets/img/icons/check-white.svg" alt="" />{" "}
                        {ele?.inclusion_name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
            {details?.exclusions?.length ? (
              <div className="col-md-6">
                <h4>Excluded</h4>
                <ul>
                  {details?.exclusions?.map((ele) => {
                    return (
                      <li>
                        <img src="/assets/img/icons/exclude-check.svg" alt="" />
                        {ele?.exclusion_name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
