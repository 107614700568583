import React from 'react'
import { Link } from 'react-router-dom'

export default function Notfound() {
    return (
        <section className='notfound'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className='col-6 col-sm-8 text-center'>
                        <img src="/assets/img/notfound.png" className='main' alt="" />
                        <h2>The requested page does not exist.</h2>
                        <Link to={'/'}><button className="btn-back" > BACK TO HOME </button></Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
