import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

export default function Attractions({ attractions, attractionName }) {
  return (
    <div className="attraction">
      <div className="container">
        <div className="header">
          <h2 className="title">ATTRACTIONS {`- ${attractionName}`}</h2>
          {/* <div className="view">
            View All
            <img src="/assets/img/icons/btn-arrow.svg" alt="" />
          </div> */}
        </div>
        <div className="row">
          {attractions?.map((ele, i) => {
            return (
              <div className="col-md-3" key={i}>
                <Link to={`/attraction-details/${ele?.handle_key}`}>
                  <div className="atraction">
                    <div className="image">
                      <img
                        src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                        alt={ele.image_alt}
                        className="w-100"
                      />
                    </div>
                    <div className="contents">
                      <h3>{ele?.title}</h3>
                      <p
                      className="attract"
                        dangerouslySetInnerHTML={{
                          __html: ele?.short_description,
                        }}
                      ></p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
