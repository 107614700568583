import React, { useEffect, useState } from "react";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import { CurrencyConversion } from "../../../utils/helpers";
import { currencyValue, updateRedux } from "../../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";

export default function Overview({
  details,
  bookNowHandler,
  flightBooking,
  err,
  packageCommonPath,
  packageCommonDetails,
}) {
  const dispatch = useDispatch();

  const selectedCurrency = useSelector(currencyValue);

  return (
    <>
      <div className="row">
        <div className="col-md-6 pe-0">
          <div className="package_features">
            {details?.averageRating ? (
              <>
                {" "}
                <div className="lists">
                  <h3>{`${details?.averageRating}/5`}</h3>
                  <h6>rating</h6>
                </div>
                <div className="lists">
                  <span className="d-flex" style={{ pointerEvents: "none" }}>
                    <StarRatings
                      rating={details?.averageRating}
                      starRatedColor="#dc1e35"
                      numberOfStars={5}
                      starEmptyColor="#e3e3e3"
                      name="rating"
                      starDimension="22px"
                      starSpacing="2px"
                    />
                  </span>
                  <h6>( {details?.totalReviews} REVIEW )</h6>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="lists">
              <div className="icon">
                <img src="/assets/img/icons/drive.svg" alt="" />
              </div>
              <h6>Self drive</h6>
            </div>
            <div className="lists">
              <h3>{details?.duration}</h3>
              <h6>Days</h6>
            </div>
            <div className="lists">
              <div className="icon">
                <img
                  src={`${IMAGE_BASE_URL}${details?.season?.path}/${details?.season?.icon}`}
                  alt={details?.season?.icon_alt}
                />
              </div>
              <h6>{details?.season?.name}</h6>
            </div>
            <div className="lists">
              <div className="icon">
                <img src="/assets/img/icons/date-from.svg" alt="" />
              </div>
              <h6>{`${details?.season?.season_months?.[0]} - ${
                details?.season?.season_months?.[
                  details?.season?.season_months?.length-1
                ]
              }`}</h6>
            </div>
            <div className="lists">
              <div className="icon">
                <img src="/assets/img/icons/km.svg" alt="" />
              </div>
              <h6>{details?.klm} KM</h6>
            </div>
          </div>
          <div className="package_details" id="overview">
            <h2>OVERVIEW</h2>
            <p dangerouslySetInnerHTML={{ __html: details?.overview }}></p>
          </div>
        </div>
        <div className="col-md-6 ps-0">
          <div className="package__pricing">
            <div className="priceGrid">
              <div className="item">
                <p>Price per person</p>
                <h6>From</h6>
                <div className="price">
                  <h3>
                    {CurrencyConversion(
                      selectedCurrency,
                      details?.adult_cost
                    ) ?? details?.adult_cost}
                  </h3>
                  <h5 className="mt-3">
                    {selectedCurrency?.currency_code ?? "USD"}
                  </h5>
                </div>
                {/* <p>total of {details?.estimate ?? details?.adult_cost} USD</p> */}
                <p>
                  {" "}
                  total of{" "}
                  {CurrencyConversion(selectedCurrency, details?.estimate) ??
                    details?.estimate}{" "}
                  {selectedCurrency?.currency_code ?? "USD"}
                </p>
                <span>Exclusive of flight cost</span>
              </div>
              <div className="item">
                <img src="/assets/img/icons/reserve.svg" alt="" />
                <p>
                  Reserve now for just ${details?.token_payment} and pay the
                  rest later!
                </p>
              </div>
            </div>
            {packageCommonDetails?.package_included?.length ? (
              <>
                <div className="price_details">
                  <ul>
                    {packageCommonDetails?.package_included?.map((ele, i) => {
                      return (
                        <li>
                          <img
                            src={`${IMAGE_BASE_URL}${packageCommonPath}/${ele?.icon}`}
                            alt={ele?.icon_alt}
                            height={20}
                          />{" "}
                          {ele?.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="search__flight">
              <div className="select__box">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    checked={flightBooking == true}
                    onChange={(e) => {
                      dispatch(
                        updateRedux({
                          key: "flight_booking",
                          value: e?.target.checked,
                        })
                      );
                    }}
                  />
                </div>
                <div className="txt" for="inlineCheckbox1">
                  <h3>Flight Booking Required?</h3>
                  <span>Select if you want to include flight book</span>
                </div>
              </div>
              <p id="error" className="error">
                {err}
              </p>
              <div className="explore">
                <button
                  className="btn btn-primary details"
                  onClick={() => bookNowHandler()}
                >
                  {flightBooking ? "Search Flight" : "Book Now"}{" "}
                  <img src="/assets/img/icons/button-arrow.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
