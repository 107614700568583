import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
const TravelCompltedModal = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <button onClick={() => setOpenModal(true)}>click me</button>

      {openModal && (
        <Modal show={openModal} centered>
          <Modal.Body className="mb-5 mt-4 ">
            <div className="travel_modal">
              <img src="/2.Travel Agency.png" alt="" />
              <h2>Travel Completed!</h2>
              <p>Your travel completed rate you experiance</p>
              <div className="rate_us_btn">
                <button>Rate Us</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default TravelCompltedModal;
