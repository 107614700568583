import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "./HomeSearch.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAirportCodes,
  getNonRelevantPackagesList,
  getPackages,
} from "../../redux/commonActionCreator";
import {
  airport_codes,
  package_loader,
  searchData,
  searchGuestData,
  updateRedux,
} from "../../redux/commonReducer";
import moment from "moment";
import { ThirtyDayFromStartDate } from "../../utils/helpers";
import { Spinner } from "react-bootstrap";

export const CounterItem = ({ index, item, onCountChangeHandler }) => {
  return (
    <div className="Item" key={index}>
      <div className="category">
        <h5>{item?.title}</h5>
        <p>{item?.ageRange}</p>
      </div>
      <div className="add-btn">
        <button
          type="button"
          onClick={() => onCountChangeHandler("sub", item, index)}
          disabled={item?.count === 0}
        >
          <img src="/assets/img/icons/subtract-minus.svg" alt="Subtract" />
        </button>
        <span>{item?.count}</span>
        <button
          type="button"
          onClick={() => onCountChangeHandler("add", item, index)}
          disabled={item?.count === 9}
        >
          <img src="/assets/img/icons/add-plus.svg" alt="Add" />
        </button>
      </div>
    </div>
  );
};

export default function HomeSearch({ btnTitle, onSearch }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterGridRef = useRef(null);

  const airportCodesList = useSelector(airport_codes);
  const data = useSelector(searchData);
  const guestData = useSelector(searchGuestData);
  const loader = useSelector(package_loader);

  const [filter, setFilter] = useState("");

  const maxEndDate = ThirtyDayFromStartDate(new Date(data?.start_date));
  const startDate = ThirtyDayFromStartDate(new Date());

  const handleOpen = (e) => {
    if (!(e == "end_date" && data?.start_date == "")) {
      setFilter(e);
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterGridRef.current &&
        !filterGridRef.current.contains(event.target)
      ) {
        handleOpen("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getAirportCodes());
  }, []);

  const onChangeHandler = (key, value) => {
    dispatch(
      updateRedux({
        key: "searchData",
        value: {
          ...data,
          [key]: value,
        },
      })
    );
    if (key == "start_date") {
      dispatch(
        updateRedux({
          key: "searchData",
          value: {
            ...data,
            [key]: value,
            end_date: "",
          },
        })
      );
    }
  };

  const onCountChangeHandler = (type, item, index) => {
    let arr = [...guestData];
    let obj = { ...arr[index] };
    obj.count =
      type == "add"
        ? Number(item?.count) + 1
        : Number(item?.count) > 0
        ? Number(item?.count) - 1
        : 0;
    arr[index] = obj;
    dispatch(
      updateRedux({
        key: "searchGuestData",
        value: arr,
      })
    );
  };

  const onSearchHandler = () => {
    let rawData = {
      id: data?.departure?._id ?? "",
      start_date: data?.start_date
        ? moment(data?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: data?.end_date
        ? moment(data?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: guestData.find((ele) => ele.key === "adults")?.count,
        age_5_12: guestData.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: guestData.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: guestData.find((ele) => ele.key === "age_less_2")?.count,
      },
    };
    dispatch(
      getPackages(rawData, () => {
        localStorage.setItem("searchData", JSON.stringify(data));
        localStorage.setItem("searchGuestData", JSON.stringify(guestData));
        dispatch(getNonRelevantPackagesList(rawData));
        if (data?.start_date && data?.end_date) {
          navigate("/search");
        } else {
          navigate("/self-drive");
        }
      })
    );
  };

  return (
    <>
      <div className="search__area">
        <div className="search__home">
          {[
            {
              id: "departure",
              icon: "departure",
              label: "Departure",
              value: data?.departure
                ? // ? `${data?.departure?.airport_name} (${data?.departure?.airport_code})`
                  `${data?.departure?.airport_city} (${data?.departure?.airport_code})`
                : "Select Departure",
            },
            {
              id: "start_date",
              icon: "arrival",
              label: "Start Date",
              // value: data?.start_date
              //   ? `${moment(data?.start_date).format("MMM Do YY")}`
              //   : "Select Date",
              // value: data?.start_date ? (
              //   <p
              //     dangerouslySetInnerHTML={{
              //       __html: `${moment(data?.start_date).format(
              //         "MMMM D"
              //       )}<sup>${moment(data?.start_date)
              //         .format("Do")
              //         .replace(/\d+/g, "")
              //         .trim()}</sup>, ${moment(data?.start_date).format(
              //         "YYYY"
              //       )}`,
              //     }}
              //   ></p>
              // ) : (
              //   "Select Date"
              // ),
              value: data?.start_date ? (
                <>
                  {moment(data?.start_date).format("MMM D")}
                  <sup>
                    {moment(data?.start_date).format("Do").replace(/\d+/g, "").trim()}
                  </sup>
                  , {moment(data?.start_date).format("YYYY")}
                </>
              ) : (
                "Select Date"
              )
            },
            {
              id: "end_date",
              icon: "arrival",
              label: "End Date",
              // value: data?.end_date ? (
              //   <p
              //     dangerouslySetInnerHTML={{
              //       __html: `${moment(data?.end_date).format(
              //         "MMMM D"
              //       )}<sup>${moment(data?.end_date)
              //         .format("Do")
              //         .replace(/\d+/g, "")
              //         .trim()}</sup>, ${moment(data?.end_date).format("YYYY")}`,
              //     }}
              //   ></p>
              // ) : (
              //   "Select Date"
              // ),
              value: data?.end_date ? (
                <>
                  {moment(data?.end_date).format("MMM D")}
                  <sup>
                    {moment(data?.end_date).format("Do").replace(/\d+/g, "").trim()}
                  </sup>
                  , {moment(data?.end_date).format("YYYY")}
                </>
              ) : (
                "Select Date"
              )
            },
            {
              id: "guests",
              icon: "guests",
              label: "Guests",
              value: guestData.reduce((sum, person) => sum + person.count, 0),
            },
          ].map(({ id, icon, label, value }) => (
            <div
              className="grid"
              key={id}
              onClick={() => handleOpen(id)}
              style={{
                cursor:
                  id == "end_date" && data?.start_date == ""
                    ? "text"
                    : "pointer",
              }}
            >
              <div className="title">
                <div className="icon">
                  <img src={`/assets/img/icons/${icon}.svg`} alt={label} />
                </div>
                <h6>
                  {label}{" "}
                  <img src="/assets/img/icons/dropdown.svg" alt="Dropdown" />
                </h6>
              </div>
              <h4>{value}</h4>
            </div>
          ))}
          <div className="grid">
            <button
              className="btn btn-primary search"
              onClick={() => (onSearch ? onSearch() : onSearchHandler())}
              disabled={loader}
              style={{ textWrap: "nowrap" }}
            >
              {!btnTitle ? "Let's Go!" : btnTitle}
              {loader && <Spinner animation="border" />}
            </button>
          </div>
        </div>

        {["departure", "start_date", "end_date", "guests"].includes(filter) && (
          <div
            className="filterGrid"
            style={{
              left:
                filter === "departure"
                  ? "0%"
                  : filter === "start_date"
                  ? "19%"
                  : filter === "end_date"
                  ? "37%"
                  : filter === "guests"
                  ? "56%"
                  : null,
            }}
            ref={filterGridRef}
          >
            {filter === "departure" ? (
              <>
                <h4>
                  <img src={`/assets/img/icons/arrival.svg`} /> Select departure
                </h4>
                <Select
                  placeholder="Departure"
                  name="unit"
                  options={airportCodesList}
                  value={data?.departure}
                  getOptionLabel={(option) =>
                    `${option.airport_name} (${option.airport_code})`
                  }
                  getOptionValue={(option) => option._id}
                  className="basic-multi-select mousePointer"
                  classNamePrefix="select"
                  onChange={(e) => onChangeHandler("departure", e)}
                  components={{ IndicatorSeparator: () => null }}
                />
              </>
            ) : filter === "start_date" ? (
              <>
                <h4>
                  <img src={`/assets/img/icons/arrival.svg`} /> Start Date
                </h4>
                <DatePicker
                  selected={data?.start_date}
                  onChange={(date) => onChangeHandler("start_date", date)}
                  // selectsStart
                  maxDate={data?.end_date}
                  placeholderText="Start Date"
                  inline
                  minDate={startDate}
                />
              </>
            ) : filter === "end_date" ? (
              <>
                <h4>
                  <img src={`/assets/img/icons/arrival.svg`} /> End Date
                </h4>
                <DatePicker
                  selected={data?.end_date}
                  onChange={(date) => onChangeHandler("end_date", date)}
                  // selectsEnd
                  startDate={data?.start_date}
                  minDate={
                    data?.start_date != "" ? data?.start_date : startDate
                  }
                  placeholderText="End Date"
                  inline
                  maxDate={maxEndDate}
                  disabled={data?.start_date == ""}
                />
              </>
            ) : (
              <div className="guests">
                {guestData.map((item, index) => (
                  <CounterItem
                    index={index}
                    item={item}
                    onCountChangeHandler={onCountChangeHandler}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
