import React, { useState, useEffect, useMemo } from "react";
import "./FileUpload.scss";
import AttachmentContent from "../AttachmentContent/AttachmentContent";
import { v4 } from "uuid";
import { Spinner } from "react-bootstrap";

import uploadIcon from "./upload.svg";

export default function FileUpload({
  reverse,
  files,
  onChange,
  className,
  error,
  loader,
  maxFileSizeMB,
  showPreview,
  inputSocket,
  field,
  info,
  multiple,
  required,
  label = "",
  buttonLabel,
  accept,
  width,
  style = {},
  condition,
  clearOnHide,
  disabled,
  noLabel,
  icon,
  closeIcon,
}) {
  let uuid = v4();

  const [input, setInput] = useState([]);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [inError, setInError] = useState("");
  // const [loader, setLoader] = useState(true)

  useEffect(() => {
    setInput(() => {
      let res = [];
      if (
        typeof files === "object" &&
        !Array.isArray(files) &&
        files?.[field] !== undefined
      )
        res = files?.[field];
      else if (
        typeof files === "object" &&
        !Array.isArray(files) &&
        files?.[field] === undefined
      )
        res = [];
      else if (files !== undefined) res = files;
      return res;
    });
    return () => setInput([]);
  }, [files]);

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field };
      if (required) obj.required = required;
      inputSocket && inputSocket(obj);
      if (Object.keys(obj).length > 1 && !inputSocket)
        setInError("Please connect 'formPlug' to 'inputSocket'");
    }
    return () => {
      inputSocket && inputSocket({ key: field, clearValidation: true });
    };
  }, [
    ...(required && typeof required === "object"
      ? Object.values(required)
      : [required]),
    condition,
  ]);

  useEffect(() => {
    return () => {
      condition &&
        clearOnHide &&
        inputSocket &&
        inputSocket({ key: field, clearValidation: true, clearValue: true });
    };
  }, [condition]);

  function fileSizeChecker(files, cb) {
    let MBdivisor = 1048576;
    let maxSize = maxFileSizeMB || 0 / MBdivisor;
    let filesSize =
      [...files].reduce((acc, crr) => acc + crr.size, 0) / MBdivisor;
    if (filesSize > maxSize) cb();
  }

  function changeHandler(e) {
  
    let candidates = [];
    if (multiple) candidates = [...input, ...fileObjectSetter(e)];
    else candidates = fileObjectSetter(e);
    let [isInvalid, msg] = validation(candidates);
    setIsInvalidFile(isInvalid);
    setInput(candidates);
    onChange && onChange(candidates, isInvalid, msg);
  }

  function closeHandler(e) {
    let [isInvalid, msg] = validation(e);
    setInput(e);
    onChange && onChange(e, isInvalid, msg, true);
  }

  function fileObjectSetter(e) {
    return Array.from(e.target.files).map((item) => {
      let type = fileParser(item.type);
      return {
        name: item.name,
        url: URL.createObjectURL(item),
        type: type,
        metaFile: item,
        isInvalid: !accept?.includes(type),
        size: item.size,
      };
    });
  }

  function validation(candidates) {
    let isInvalid = false;
    let msg = "";
    maxFileSizeMB &&
      fileSizeChecker(candidates, () => {
        isInvalid = true;
        msg = `File size shouldn't exceed ${maxFileSizeMB} MB`;
      });
    if (candidates.some((item) => item.isInvalid)) {
      isInvalid = true;
      msg = "Invalid format";
    }
    return [isInvalid, msg];
  }

  function fileParser(type) {
    switch (type) {
      case "application/pdf":
      case "pdf":
        return "pdf";
      case "application/msword":
      case "doc":
        return "doc";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "docx":
        return "docx";
      case "video/quicktime":
      case "mov":
        return "mov";
      case "video/mp4":
      case "mp4":
        return "mp4";
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "xlsx":
        return "xlsx";
      case "image/jpg":
      case "jpg":
        return "jpg";
      case "image/jpeg":
      case "jpeg":
        return "jpeg";
      case "image/png":
      case "png":
        return "png";
      case "image/avif":
      case "avif":
        return "avif";
      case "image/svg+xml":
      case "svg":
        return "svg";
      case "image":
        return "image";
      case "text/csv":
      case "csv":
        return "csv";
      case "application/vnd.ms-powerpoint":
      case "ppt":
        return "ppt";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "pptx":
        return "pptx";
      case "application/vnd.ms-excel":
      case "xls":
        return "xls";
      default:
        return "";
    }
  }

  if (!condition && condition !== undefined) return null;

  return (
    <span id="FileUpload" className={className} style={{ ...style }}>
      <span className="wrapper" style={{ width: width }}>
        {label && (
          <p className="label">
            {label}
            {(required?.condition !== undefined
              ? required?.condition
              : required) && <em>*</em>}
            {info}
          </p>
        )}
        <span>
          <label
            htmlFor={"attach_" + uuid}
            className={reverse ? "reverse" : ""}
          >
            <input
              type="file"
              id={"attach_" + uuid}
              hidden
              multiple={multiple}
              onClick={(e) => (e.target.value = null)}
              onChange={changeHandler}
              accept={accept?.map((item) => "." + item)}
              disabled={disabled}
            />
            {buttonLabel}
            {loader ? (
              <Spinner animation="border" role="status" size="sm"></Spinner>
            ) : (
              <img className="upload_icon" src={icon || uploadIcon} />
            )}
          </label>
          <a
            className={
              "file_info" + (input[0] && !isInvalidFile ? " file" : "")
            }
            target="_blank"
            href={input.length !== 0 ? input[0]?.url : ""}
            rel="noreferrer"
          >
            {noLabel
              ? "No file chosen"
              : input[0]?.name
              ? isInvalidFile
                ? "Invalid file"
                : input[0].name
              : "No file chosen"}
          </a>
        </span>
        {(inError || (typeof error === "object" ? error?.[field] : error)) && (
          <small id="error" className="error">
            {inError ||
              (typeof error === "object" ? error?.[field] || "" : error)}
          </small>
        )}
      </span>
      {showPreview && !loader && (
        <AttachmentContent
          files={input}
          closeButton
          onClose={closeHandler}
          accept={accept}
          closeIcon={closeIcon}
        />
      )}
    </span>
  );
}

{
  /*

<FileUpload
  className=''
  label
  buttonLabel
  files={[
    {
      type: 'pdf',
      url: '',
      name: ''
    }
  ]}
  accept={['jpeg', 'pdf', 'doc', 'docx', 'xlsx', 'png', 'mp4']}
  onChange={(e, isInvalid, message) => { }}
  error=''
  info=''
  showPreview
  reverse
  multiple
/>

*/
}
