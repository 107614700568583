import React from "react";
import { Accordion } from "react-bootstrap";

export default function EssentialInfo({ details }) {
  return (
    <div className="essential_info mt-5">
      <div className="container">
        <h2 className="title">ESSENTIAL INFORMATION</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: details?.essentialInfoDesc,
          }}
        ></p>

        <Accordion>
          {details?.essentialInfo?.map((ele, i) => {
            return (
              <Accordion.Item eventKey={i}>
                <Accordion.Header>{ele?.question}</Accordion.Header>
                <Accordion.Body>
                  <div className="collapse__content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: ele?.description,
                      }}
                    ></p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}
