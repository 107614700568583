import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeMain from "../Pages/web/Home";
import About from "../Pages/web/About";
import Curated from "../Pages/web/Curated";
import Blogs from "../Pages/web/Blogs";
import BlogDetails from "../Pages/web/BlogDetails";
import SelfDrive from "../Pages/web/SelfDrive";
import Contact from "../Pages/web/Contact";
import SearchTours from "../Pages/web/Search/SearchTours";
import PackagesDetails from "../Pages/web/packageDetails/PackagesDetails";
import ActivityDetails from "../Pages/web/ActivityDetails";
import AttractionDetails from "../Pages/web/AttractionDetails";
import PackageBooking from "../Pages/web/packageDetails/Bookings/PackageBooking";
import CancelPolicy from "../Pages/web/CancelPolicy";
import BookingProcess from "../Pages/web/BookingProcess";
import ManageBooking from "../Pages/web/ManageBooking";
import TermsAndCondition from "../Pages/web/TermsAndCondition";
import TravellerDetails from "../Pages/admin/traveller/TravellerDetails";
import EngagementDeatils from "../Pages/admin/engagnment-details/EngagementDeatils";
import Itinerary from "../Pages/admin/itinerary/Itinerary";
import PaymentQuote from "../Pages/admin/payments/PaymentQuote";
import Documents from "../Pages/admin/documents/Documents";
import PaymentSuccess from "../Pages/admin/payments/PaymentSuccess";
import TravelCompltedModal from "../components/admin/TravelCompltedModal";
import Home from "../Pages/admin/home/Home";
import Layout from "../components/admin/Layout";
import PrivateRoutes from "../utils/PrivateRoutes";
import Timeline from "../Pages/admin/payments/TimeLine";
import LayoutWebsite from "../components/web/LayoutWebsite";
import PrivacyPolicies from "../Pages/web/PrivacyPolicies";
import PageNotFound from "../PageNotFound";
import Notfound from "../Pages/web/Notfound";

function RouteHandler() {
  return (
    <Routes>
      <Route path="/" element={<LayoutWebsite />}>
        <Route path="/" element={<HomeMain />} />
        <Route path="/about" element={<About />} />
        <Route path="/curated" element={<Curated />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs-details/:id" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/activity-details/:id" element={<ActivityDetails />} />
        <Route path="/attraction-details/:id" element={<AttractionDetails />} />
        <Route path="/cancel-policy" element={<CancelPolicy />} />
        <Route path="/terms-condition" element={<TermsAndCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicies />} />
        <Route path="/booking-process" element={<BookingProcess />} />
        <Route path="/self-drive" element={<SelfDrive />} />
        <Route path="/package-details/:id" element={<PackagesDetails />} />
        <Route path="/package-booking" element={<PackageBooking />} />
        <Route path="/search" element={<SearchTours />} />
        <Route path="/manage-booking" element={<ManageBooking />} />
        <Route path="/package-details/:id/:booking_id" element={<PackagesDetails />} />
        <Route path="/404" element={<Notfound />} />
      </Route>
      {/* ADMIN */}
      <Route element={<PrivateRoutes />}>
        <Route element={<Layout />}>
          <Route path="/admin" element={<Home />} />
          <Route path="/traveller-details" element={<TravellerDetails />} />
          <Route path="/engagement-details" element={<EngagementDeatils />} />
          <Route path="/itinerary" element={<Itinerary />} />
          <Route path="/payments" element={<PaymentQuote />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/payment-section" element={<Timeline />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/modal" element={<TravelCompltedModal />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RouteHandler;
