import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  currency_data,
  currencyValue,
  general_details,
  general_path,
  updateRedux,
} from "../redux/commonReducer";
import { getCurrency } from "../redux/commonActionCreator";
import { IMAGE_BASE_URL } from "../constants/configuration";

export default function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options = [
    { value: "USD", label: "USD" },
    { value: "INR", label: "INR" },
    { value: "ALL", label: "ALL" },
  ];

  const currency = useSelector(currencyValue);
  const currencyData = useSelector(currency_data);
  const path = useSelector(general_path);
  const generalDetails = useSelector(general_details);

  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchShow, setSearchShow] = useState(false);

  useEffect(() => {
    if (currencyData) {
      dispatch(
        updateRedux({
          key: "currencyValue",
          value: currencyData?.find((ele) => ele?.handle_key === "usd"),
        })
      );
    }
  }, [currencyData]);

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // MENU OPEN
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  // SEARCH
  const handleSearch = () => {
    setSearchShow(!searchShow);
  };

  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };
  return (
    <>
      <div
        className={`main__header ${scrolled ? "scrolled" : ""} ${
          location.pathname !== "/" || location.pathname !== "/curated"
            ? "icon-change"
            : ""
        }`}
      >
        <div className="container">
          <div className={`header ${location.pathname === "/404" ?  "not_found" :""}`}>
            <div className="logo">
              <Link to="/">
                <img src="/assets/img/logo.svg" alt="" />
              </Link>
            </div>
            <div className="menu">
              <ul>
                {window.location.href.includes("manage-booking") ? (
                  <></>
                ) : (
                  <li
                    onClick={() => navigate("/manage-booking")}
                    className="pointer"
                  >
                    {localStorage.getItem("token") ? "View Booking" : "Login"}{" "}
                    <img src="/assets/img/icons/login.svg" alt="" />
                  </li>
                )}

                <li onClick={handleSearch} className="pointer">
                  <img src="/assets/img/icons/search.svg" alt="" />
                </li>
                <li className="pointer price-dropdown">
                  <Select
                    options={currencyData}
                    getOptionLabel={(o) => o?.currency_code}
                    getOptionValue={(o) => o?.currency_code}
                    placeholder="USD"
                    value={currency || null}
                    styles={customStyles}
                    menuPortalTarget={document.body}
                    onChange={(ele) =>
                      dispatch(
                        updateRedux({
                          key: "currencyValue",
                          value: ele,
                        })
                      )
                    }
                  />
                  {/* <select
                    class="form-select"
                    aria-label="Default select example"
                    value={currency ? JSON.stringify(currency) : ""}
                    onChange={(e) =>
                      dispatch(
                        updateRedux({
                          key: "currencyValue",
                          value: JSON.parse(e.target.value),
                        })
                      )
                    }
                  >
                    {/* <option selected>USD</option>
                    <option value="1">INR</option>
                    <option value="2">AED</option> */}
                  {/* {currencyData?.map((ele, i) => {
                      return (
                        <option
                          key={i}
                          selected={JSON.stringify(ele)}
                          value={JSON.stringify(ele)}
                        >
                          {ele?.currency_code}
                        </option>
                      );
                    })}
                  </select>  */}
                </li>
                <li onClick={handleMenuOpen} className="pointer">
                  <img src="/assets/img/icons/menu.svg" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={`sidemenu__wrapper ${menuOpen ? "show" : ""}`}>
        <div className="sidemenu__content">
          <button className="closeButton sideMenuCls" onClick={handleMenuOpen}>
            <img src="/assets/img/icons/close-menu.svg" alt="icon" />
          </button>
          <div className="widget footer_widget">
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about">About</NavLink>
              </li>
              <li>
                <NavLink to="/self-drive">Self Drive</NavLink>
              </li>
              <li>
                <NavLink to="/curated">Curated Tours</NavLink>
              </li>
              <li>
                <NavLink to="/blogs">Blogs</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
          {/* <div className="social__links">
            <ul>
              <li>
                <Link to="/">
                  <img src="/assets/img/icons/facebook.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/assets/img/icons/x.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/assets/img/icons/instagram.svg" alt="" />
                </Link>
              </li>
            </ul>
          </div> */}
          <div className="social__links">
            <ul>
              {generalDetails?.social_media?.map((ele, i) => {
                return (
                  <li
                    onClick={() => {
                      window.open(
                        ele?.link?.startsWith("http")
                          ? ele.link
                          : `https://${ele.link}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    <img
                      src={`${IMAGE_BASE_URL}${path}/${ele?.media_icon}`}
                      alt={ele?.media_icon_alt}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* SEARCH */}
      <div
        className={`sidemenu__wrapper search_wrapper ${
          searchShow ? "show" : ""
        }`}
      >
        <div className="sidemenu__content search_bar">
          <button className="closeButton sideMenuCls" onClick={handleSearch}>
            <img src="/assets/img/icons/close-menu.svg" alt="icon" />
          </button>
          <div className="widget footer_widget">
            <div className="search__area">
              <h2>Find What You're Looking For</h2>
              <p>
                Discover your dream Icelandic adventure with Skyward's tailored
                search—find the perfect tour package just for you.
              </p>
              <input
                type="text"
                className="form-control"
                placeholder="Search Here.."
              />
              <button className="btn btn-primary">Search</button>
            </div>
          </div>
          <div className="social__links">
            <ul>
              <li>
                <Link to="/">
                  <img src="/assets/img/icons/facebook.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/assets/img/icons/x.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="/assets/img/icons/instagram.svg" alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
