import React from "react";

const RentalDetails = ({ heading, date, time, location }) => {
  return (
    <>
      <div className="pickup_header">
        <h6>{heading}</h6>
      </div>
      <div className="row ">
        <div className="col-12 col-md-4 table_layout">
          <div className="car_rental_table px-2">
            <h4>Date</h4>
            <p>{date}</p>
          </div>
        </div>
        <div className="col-12 col-md-3 table_layout">
          <div className="car_rental_table px-2">
            <h4>Time</h4>
            <p>{time}</p>
          </div>
        </div>
        <div className="col-12 col-md-5 ">
          <div className="car_rental_table px-2">
            <h4>Location</h4>
            <p>{location}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalDetails;
