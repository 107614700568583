import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import { updateAdminRedux } from "./adminReducer";

export const uploadFile = (formData, callback, progress) => (dispatch) => {
  dispatch(updateAdminRedux({ key: "fileUploadLoader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}uploadFile`, formData, {
      onUploadProgress: (e) =>
        progress && progress(Math.round(e.progress * 100)),
    })
    .then((res) => {
      dispatch(updateAdminRedux({ key: "fileUploadLoader", value: false }));
      if (res.data.status == true) {
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateTravellerInfo = (data, callback) => (dispatch) => {
  dispatch(updateAdminRedux({ key: "update_traveler_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}updateTravellerInfo`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateAdminRedux({ key: "update_traveler_loader", value: false }));
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        callback && callback(res.data)
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateAdminRedux({ key: "update_traveler_loader", value: false }));
      console.log(err);
    });
};

export const getCustomerEngagementDetails = (id) => (dispatch) => {
  dispatch(updateAdminRedux({ key: "customer_engagement_details_loading", value: true}));
  axiosInstance
    .get(`${API_BASE_URL}getCustomerEngagementDetails/${id}`)
    .then((res) => {
      dispatch(updateAdminRedux({ key: "customer_engagement_details_loading", value: false}));
      if (res.data.status) {
        dispatch(updateAdminRedux({ key: "customer_engagement_details", value: res.data.booking_details }));
      }
    })
    .catch((err) => {
      dispatch(updateAdminRedux({ key: "customer_engagement_details_loading", value: false}));
      console.log(err);
    });
};

export const getBookingItinerary = (id) => (dispatch) => {
  dispatch(updateAdminRedux({ key: "customer_itinerary_loading", value: true}));
  axiosInstance
    .get(`${API_BASE_URL}getBookingItinerary/${id}`)
    .then((res) => {
      dispatch(updateAdminRedux({ key: "customer_itinerary_loading", value: false}));
      if (res.data.status) {
        dispatch(updateAdminRedux({ key: "customer_itinerary_details", value: res.data.booking_details[0] }));
      }
    })
    .catch((err) => {
      dispatch(updateAdminRedux({ key: "customer_itinerary_loading", value: false}));
      console.log(err);
    });
};

export const getBookingPayment = (id) => (dispatch) => {
  dispatch(updateAdminRedux({ key: "booking_payment_loading", value: true}));
  axiosInstance
    .get(`${API_BASE_URL}getBookingPayment/${id}`)
    .then((res) => {
      dispatch(updateAdminRedux({ key: "booking_payment_loading", value: false}));
      if (res.data.status) {
        dispatch(updateAdminRedux({ key: "booking_payment_details", value: res.data.booking_details[0] }));
      }
    })
    .catch((err) => {
      dispatch(updateAdminRedux({ key: "booking_payment_loading", value: false}));
      console.log(err);
    });
};

export const getBookingStatus = (id) => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getBookingStatus/${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateAdminRedux({ key: "booking_status", value: res.data.booking_details }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAccountsDetails = () => (dispatch) => {
  axiosInstance
    .get(`${API_BASE_URL}getAccountsDetails`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateAdminRedux({ key: "accounts_details", value: res.data.accounts_details }));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updatePaymentReceipt = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}updatePaymentReceipt`,data)
    .then((res) => {
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        callback && callback()
      }else{
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updatePayment = (data, callback) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}updatePayment`, data)
    .then((res) => {
      if (res.data.status) {
        // toast.success(res.data.message, {
        //   position: "bottom-center",
        //   autoClose: 3000,
        // });
        if(callback){
          callback(res.data)
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if(callback){
          callback(res.data)
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addReview = (data, callback) => (dispatch) => {
  dispatch(updateAdminRedux({ key: "update_review_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}addReview`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateAdminRedux({ key: "update_review_loader", value: false }));
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        callback && callback()
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateAdminRedux({ key: "update_review_loader", value: false }));
      console.log(err);
    });
};
