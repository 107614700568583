import React from "react";

export default function Highlights({ details }) {
  return (
    <div className="highlights">
      <div className="container">
        <div className="header">
          <h2 className="title">highlight</h2>
        </div>
        <div className="row">
          {details?.highlights?.map((ele, i) => {
            return (
              <div className="col-md-6" key={i}>
                <div className="highlight_grid">
                  <h4>{ele?.title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: ele?.description }}></p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
