import React, { useState } from "react";
import Slider from "react-slick";
import { hotel_images, partners_opt } from "../../../components/web/config";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

export default function HotelExperience({ details }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const hotelData = details?.hotelexp;

  const getThumbnail = (url) => {
    const videoId = url.split("/").pop();
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  const playVideo = () => {
    setIsPlaying(true);
  };

  return (
    <>
      <div className="hotel_experience">
        <div className="container">
          <h3>HOTEL EXPERIENCE</h3>
          <h2 className="title">{hotelData?.tagline}</h2>
          <div className="row align-items-center justify-content-center mt-5 mb-5">
            <div className="col-md-5 col-tab-2">
              <img
                src={`${IMAGE_BASE_URL}${hotelData?.path}/${hotelData?.image}`}
                alt={hotelData?.image_alt}
                className="main"
              />
            </div>
            <div className="col-md-5 col-tab-2">
              <ul>
                {hotelData?.desc_points?.map((ele) => {
                  return (
                    <li>
                      <img src="/assets/img/icons/check.svg" alt="" /> {ele}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="partners_grid">
            <h3>OUR PARTNERS</h3>
            {hotelData?.partners?.length ? (
              <Slider {...partners_opt}>
                {hotelData?.partners?.map((ele, index) => (
                  <div className="partners_lists" key={index}>
                    <img
                      src={`${IMAGE_BASE_URL}${hotelData?.partner_path}/${ele?.image}`}
                      className="w-100"
                      alt={ele.alt}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="container-fluid">
          <div className="hotel_images">
            {hotelData?.gallery?.length ? (
              <Slider {...hotel_images}>
                {hotelData?.gallery?.map((ele, index) => (
                  <div key={index}>
                    {ele?.type == "image" ? (
                      <img
                        src={`${IMAGE_BASE_URL}${hotelData?.gallery_path}/${ele?.image}`}
                        alt={ele.alt}
                        className="w-100"
                      />
                    ) : (
                      <div onClick={playVideo}>
                        {isPlaying ? (
                          <iframe
                            src={ele?.image}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        ) : (
                          <div className="video">
                            <div className="play">
                              <img
                                src="/assets/img/icons/play.svg"
                                alt="Play"
                              />
                            </div>
                            <img
                              src={getThumbnail(ele?.image)}
                              alt={ele.alt}
                              className="w-100"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
